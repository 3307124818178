import { Icon } from './icon';
import { IIcon } from './icon/interfaces';

export const SlashIcon = (props: IIcon) => {
  return (
    <Icon width='24' height='24' {...props}>
      <path
        d='M11 18.25L13.5 5.75'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </Icon>
  );
};
