import { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { DeviceRecommendation } from './components/DeviceRecommendation';
import { useAsyncProcessManager } from './external/extension/tools/async';
import { AsyncProcessManagerContext } from './external/extension/tools/async/context';
import { useStripeRedirectToStripePage } from './hooks/useStripeRedirectToStripePage';
import { Routes } from './routes';
import { useSetSettingsMutation } from './services/settings';
import { PAGES_ENUM } from './utils/constants';

// This routes are adapted for mobile, tablet and desktop versions
const MOBILE_ROUTES = [
  PAGES_ENUM.SIGN_IN,
  PAGES_ENUM.SIGN_UP,
  PAGES_ENUM.ONBOARDING_FIRST_STEP,
  PAGES_ENUM.ONBOARDING_SECOND_STEP,
  PAGES_ENUM.ONBOARDING_PAYMENT,
  PAGES_ENUM.TERMS_OF_SERVICE,
  PAGES_ENUM.FORGOT_PASSWORD,
  PAGES_ENUM.RESET_PASSWORD,
  PAGES_ENUM.CODE_CONFIRMATION,
];

const App = () => {
  const location = useLocation();
  const [setSettings] = useSetSettingsMutation();
  // This will be deleted after mobile and desktop design will be implemented for all pages
  const isMobileAdaptedRoute = useMemo(
    () => MOBILE_ROUTES.some(route => location.pathname === route),
    [location.pathname]
  );
  const { onStripeRedirectToStripePage } = useStripeRedirectToStripePage();

  const redirectFromExtensionByPriceToPayPage = async () => {
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get('token');

    if (token) {
      localStorage.setItem('auth_token', token);
      localStorage.setItem('onboarding_passed', 'true');
      sessionStorage.setItem('onboarding_passed', 'true');
      setSettings({ onboarding_passed: true });
      onStripeRedirectToStripePage();
    }
  };

  useEffect(() => {
    if (location.pathname.includes('extension-pay-page')) {
      redirectFromExtensionByPriceToPayPage();
    }
  }, []);

  const asyncProcessManager = useAsyncProcessManager({
    onFirstActiveStart: () => {
      // setIsLoading(true);
    },
    onAllFinished: () => {
      // setIsLoading(false);
    },
    onError: (e, _, message, process) => {
      console.error(`🤖 API error '${message}' on ${process.name}`);
    },
  });

  if (location.pathname.includes('extension-pay-page')) return null;

  return (
    <>
      <div className={`${isMobileAdaptedRoute ? '' : 'app-content-hidden'}`}>
        <AsyncProcessManagerContext.Provider value={asyncProcessManager}>
          <Routes />
        </AsyncProcessManagerContext.Provider>
      </div>
      {isMobileAdaptedRoute ? null : <DeviceRecommendation />}
    </>
  );
};

export default App;
