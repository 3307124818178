import cn from 'classnames';
import { FC } from 'react';
import { Link } from 'react-router-dom';

import { EditIcon } from '../../../../assets/svg/EditIcon';
import { ShareIcon } from '../../../../assets/svg/ShareIcon';
import { SlashIcon } from '../../../../assets/svg/SlashIcon';
import { Badge } from '../../../../components/Badge';
import { Avatar } from '../../../../external/extension/ui/design/components/avatar';
import { ICustomPrompt } from '../../../../models/IPromptLibrary';
import { ICategory } from '../../../../models/IShared';
import { PAGES_ENUM } from '../../../../utils/constants';
import { UsageAndViewBadges } from '../UsageAndViewBadges';
import styles from './CustomPrompt.module.scss';

type Props = {
  prompt: ICustomPrompt;
  onEdit: () => void;
  onAddToCommand: (prompt: ICustomPrompt, promptType: number) => void;
  onPromptSelect: (id: number) => void;
};

export const CustomPrompt: FC<Props> = ({ prompt, onEdit, onAddToCommand, onPromptSelect }) => {
  const {
    id,
    categories,
    name,
    description,
    click_amount,
    amount_of_lookups,
    user,
    editable,
    is_in_command,
  } = prompt;
  const { username, avatar } = user || {};

  const hasCategories = categories?.length !== 0;

  const handlePromptSelection = () => {
    onPromptSelect(id);
  };

  return (
    <div className={styles.customPrompt} onClick={handlePromptSelection}>
      <div
        className={styles.customPromptHeading}
        style={{ justifyContent: hasCategories ? 'space-between' : 'flex-end' }}
      >
        <div className={styles.categoriesWrapper}>
          {(categories as ICategory[])?.map(category => {
            return (
              <Badge
                key={category?.id}
                icon={category?.icon}
                title={category?.parent?.name ? category.parent.name : category?.name}
                color={category?.color ?? '#0BA5EC'}
                background={category?.background_color ?? 'rgba(11, 165, 236, 0.16)'}
                subcategory={category?.parent && category?.name}
              />
            );
          })}
        </div>

        <div className={styles.customPromptButtonsContainer}>
          <button
            className={cn(styles.customPromptCommandBtn, {
              [styles.customPromptCommandBtnActive]: is_in_command,
            })}
            onClick={e => {
              e.stopPropagation();
              onAddToCommand(prompt, 1);
            }}
          >
            <SlashIcon />
          </button>
          <button
            className={styles.customPromptEditBtn}
            onClick={e => {
              e.stopPropagation();
              onEdit();
            }}
          >
            <EditIcon />
          </button>
          {editable && (
            <Link
              to={`${PAGES_ENUM.SHARE_CUSTOM_PROMPT}/${id}`}
              className={styles.customPromptShareBtn}
              onClick={e => e.stopPropagation()}
            >
              <ShareIcon />
            </Link>
          )}
        </div>
      </div>
      <div className={styles.customPromptContent}>
        <div className={styles.customPromptTitleAndDescription}>
          <h3 className={styles.customPromptTitle}>{name}</h3>
          <p className={styles.customPromptDescription}>{description}</p>
        </div>
        <div className={styles.customPromptUserAndEngagementInfo}>
          {username && (
            <div className={styles.customPromptUserInfo}>
              <Avatar size="nano" userName={username} url={avatar} />
              <span>{username}</span>
            </div>
          )}
          <UsageAndViewBadges usage={click_amount} views={amount_of_lookups} />
        </div>
      </div>
    </div>
  );
};
