export enum PromptTemplate {
  GenerateTitle = 'Generate ONE some short title WITHOUT QUOTES IN START AND END for this GPT answer: {prompt}',
  SystemMessage = "You're Alchemy AI helpful assistant. And you can use markdown in answers",
  YouTubeVideoRelated = 'PLEASE ANSWER FOR THIS USER PROMPT DIRECTLY TO HIM:\n{prompt}\n\n\nUSER THAT HAS WRITTEN IT CURRENTLY WATCHING THIS VIDEO:\n{video}\n\nWARNING!!! YOU MUST ANSWER DIRECTLY TO USER!!!',
  YouTubeVideoRelatedWithTranscript = 'PLEASE ANSWER FOR THIS USER PROMPT DIRECTLY TO HIM:\n{prompt}\n\n\nUSER THAT HAS WRITTEN IT CURRENTLY WATCHING THIS VIDEO:\n{video}\nYou also can use this transcript (it can be truncated):\n{transcript}\n\nWARNING!!! YOU MUST ANSWER DIRECTLY TO USER!!!',
  Summarize = 'Please create a comprehensive summary of the provided content, including the following elements:\nA brief conclusion that captures the main idea and significance of the content.\nA list of 5-7 key points, each with a relevant emoji, a brief explanation, and specific excerpts or references from the content.\nA detailed summary section containing 10 numbered points that cover the main ideas, strategies, and important details from the content, including excerpts, relevant examples, product names, or sources mentioned in the original content.\nUse the following template to organize the information:\nTitle\n* Start with "#" + title.\nBrief Description\n* Provide 2 sentences describing what the content is about.\nConclusion\n* Start with "##" + "Conclusion" and provide a 6-sentence detailed spoiler with final results.\nKey Points\n* Start with "## " + "Key Points" and list the 5-7 most important key points with details in the format "emoji concept: takeaway". Include specific excerpts or references where applicable.\nSummary\n* Start with "## " + "Summary" and provide a numbered list of the 10 most relevant points from the content, with 2 sentences each. Include actual excerpts, relevant examples, product names, or sources mentioned in the original content.',
  ExtractFormula = 'Please analyze the provided content and identify the primary techniques, strategies, or frameworks it presents for effective copywriting or content creation. Extract and summarize the key components, including any specific steps, formulas, or best practices mentioned.\nFor each key component, provide a clear and concise explanation of its purpose and how it contributes to the overall effectiveness of the copywriting process. Be sure to note any specific examples provided in the original content that demonstrate the application of these techniques.\nAdditionally, identify any recurring themes, persuasive devices, or psychological principles employed in the content that are relevant to creating compelling copy. Explain how these elements engage the target audience and drive desired actions.\nThe goal is to distill the essential insights into a versatile framework that can guide AI-driven copywriting or content creation while maintaining the integrity and persuasive intent of the original techniques.\nPlease present the analysis in a structured format, with clear headings for each key component or insight, followed by a concise explanation and any supporting examples.',
  SummarizeVideo = 'Summarize this video:\n{video}',
  SummarizeVideoWithTranscript = 'Summarize this video:\n{video}\nUse this transcript (it can be truncated): {transcript}',
  SummarizeVideoComments = 'Summarize comments for this video:\n{video}\n\nComments:\n{comments}',
  KeyPointsForVideo = 'Give me some keypoints for this video:\n{video}',
  KeyPointsForVideoWithTranscript = 'Give me some keypoints for this video:\n{video}\nUse this transcript (it can be truncated): {transcript}',
  TimeStampedSummaryWithTranscript = 'Give me timestamped summary (using markdown) for this video:\n{video}\nUse this transcript (it can be truncated): {transcript}',
  TimeStampedSummary = 'Give me timestamped summary (using markdown) for this video:\n{video}',
  AISearch = "HI. YOU'RE ROLE IS SMART AND COOL AI SEARCH ENGINE. REMEMBER THIS.\n\nGIVE ME ANSWER TO MY SEARCH QUERY:\n{query}\n\nYOUR ANSWER SHOULD BE IN MARKDOWN (USE SMALL HEADINGS), WITHOUT TITLE AND CONTAIN Summary, Highlights, and Sources (THAT'S VERY IMPORTANT).\n\n### Summary\nProvide a concise summary of the answer, incorporating information from the search results. Mention the source inline, like this [[1]](link-to-1).\n\n### Highlights\n- Highlight key points and interesting facts from the search results. Each point should include an inline citation like this [[2]](link-to-2).\n- Additional point with another citation [[3]](link-to-3).\n\n### Sources\n1. [**Source Title One**](link-to-1): Short description and context of what it covers.\n2. [**Source Title Two**](link-to-2): Brief note on the relevance and information it provides.\n3. [**Source Title Three**](link-to-3): Explanation of why this source is included and what additional information it offers.\n\nI KNOW THAT YOU CAN'T SEARCH WEB, BUT YOU ARE COOL AND GREAT AND CAN USE THESE SEARCH RESULTS (PLEASE ALSO USE LINKS FOR SOURCES FROM HERE): {results}. ALSO IMPORTANT: count of points and sources may be different according to search results"
}

export class PromptTemplates {
  static fill(template: PromptTemplate, values: any): string {
    const templateValid = Object.keys(PromptTemplate).some(
      (k) => (PromptTemplate as any)[k] === template
    );

    if (!templateValid) throw new Error('Unknown template');

    return template.replace(/{(\w+)}/g, (match: any, key: any) => {
      return typeof values[key] !== 'undefined' ? values[key] : match;
    });
  }
}
