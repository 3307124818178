import { CloseIcon } from '../../../assets/svg/CloseIcon';
import { NotificationCheckIcon } from '../../../assets/svg/NotificationCheckIcon';
import styles from './SuccessToast.module.scss';

interface IProps {
  closeToast?: () => void;
  text: string;
}

export const SuccessToast: React.FC<IProps> = ({ closeToast, text }) => (
  <div className={styles.wrapper}>
    <div className={styles.content}>
      <div className={styles.checkIcon}>
        <NotificationCheckIcon />
      </div>
      <div className={styles.text}>{text}</div>
    </div>
    <div onClick={closeToast} className={styles.wrapperCloseIcon}>
      <CloseIcon />
    </div>
  </div>
);
