import cn from 'classnames';
import { FC, ReactElement, SVGProps, useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';

import ArrowDownIcon from '../../../../external/extension/ui/design/assets/svg/icons/ArrowDownIcon';
import styles from './NavList.module.scss';

export interface INavItem {
  id: number;
  icon?: ReactElement<FC<SVGProps<SVGSVGElement>>>;
  title: string;
  link?: string;
  externalLink?: string;
}

interface IItem {
  icon?: ReactElement<FC<SVGProps<SVGSVGElement>>>;
  title: string;
  link?: string;
  externalLink?: string;
  subItems?: IItem[];
}

type Props = {
  items?: IItem[];
  isUpgradeNeeded?: boolean;
  isLoading: boolean;
  isSubscribtionFetching?: boolean;
  className?: string;
};

export const NavList: FC<Props> = ({ items, className, isLoading }) => {
  const location = useLocation();
  const [expandedItem, setExpandedItem] = useState<number | null>(null);

  const getNavLinkClassName = (path: string, withSubItemsAndExpanded?: boolean) => {
    const pathWithSearchParams = location.pathname + location.search;

    return cn(styles.navLink, {
      [styles.navActiveLink]:
        pathWithSearchParams === path ||
        ((path === '/chat' || path === '/synthesize') && pathWithSearchParams.startsWith(path)) ||
        (path === '/prompts' &&
          (location.search.includes('all') ||
            location.search.includes('favorite') ||
            location.search.includes('custom')) &&
          !withSubItemsAndExpanded),
    });
  };

  const toggleExpand = (index: number, event: React.MouseEvent) => {
    event.preventDefault();
    setExpandedItem(expandedItem === index ? null : index);
  };

  useEffect(() => {
    if ((location.pathname + location.search).startsWith('/prompts?tab=')) {
      setExpandedItem(items.findIndex(item => !!item.subItems) ?? null);
    }
  }, [location]);

  return (
    <div className={cn(styles.navList, className)}>
      {items?.map((item, index) => (
        <div key={index} className={styles.navSection}>
          <nav className={styles.navLinks}>
            <NavLink
              to={item.link}
              className={getNavLinkClassName(
                item.link || '',
                !!item.subItems && expandedItem === index
              )}
              onClick={event => (item.subItems ? toggleExpand(index, event) : undefined)}
              style={isLoading ? { pointerEvents: 'none' } : { pointerEvents: 'auto' }}
            >
              {item.icon}
              {item.title}
              {item.subItems && (
                <ArrowDownIcon
                  className={cn(styles.arrowIcon, {
                    [styles.expanded]: expandedItem === index,
                  })}
                />
              )}
            </NavLink>
            <div
              className={cn(styles.subItems, {
                [styles.expanded]: expandedItem === index,
              })}
            >
              {item.subItems?.map((link, subIndex) => (
                <NavLink
                  key={subIndex}
                  to={link.link}
                  className={getNavLinkClassName(link.link)}
                  style={isLoading ? { pointerEvents: 'none' } : { pointerEvents: 'auto' }}
                >
                  {link.icon}
                  {link.title}
                </NavLink>
              ))}
            </div>
          </nav>
        </div>
      ))}
    </div>
  );
};
