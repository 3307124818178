import { Icon } from '../../components/Icon/Icon';
import { IIcon } from '../../components/Icon/IIcon';

export const ShareIcon = (props: IIcon) => {
  return (
    <Icon width="24" height="24" {...props}>
      <path
        d="M8.25 10.75H7.75C6.64543 10.75 5.75 11.6454 5.75 12.75V17.25C5.75 18.3546 6.64543 19.25 7.75 19.25H16.25C17.3546 19.25 18.25 18.3546 18.25 17.25V12.75C18.25 11.6454 17.3546 10.75 16.25 10.75H15.75M12 14.25V4.75M12 4.75L9.75 7.25M12 4.75L14.25 7.25"
        stroke="#989898"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
};
