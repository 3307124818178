import cn from 'classnames';
import { FC } from 'react';
import Skeleton from 'react-loading-skeleton';

import { CheckboxIconChecked } from '../../../../../assets/svg/CheckboxIconChecked';
import { CheckConfirmationIcon } from '../../../../../assets/svg/CheckConfirmationIcon';
import { Button } from '../../../../../components/Button';
import { useTypedSelector } from '../../../../../hooks/useTypedSelector';
import { SUBSCRIPTION_TIER } from '../../../../../models/ISubscription';
import { selectTheme, ThemeOptions } from '../../../../../redux/Theme';
import styles from './BillingCard.module.scss';
import { IBillingCard } from './IBillingCard';
import { PopularLabel } from './PopularLabel';

export const BillingCard: FC<
  IBillingCard & { onClick: () => void; getIsActiveBtn: (tier: SUBSCRIPTION_TIER) => boolean }
> = ({
  title,
  price,
  buttonText,
  benefits,
  isPopular,
  timePeriod,
  tier,
  onClick,
  getIsActiveBtn,
  isLoading,
  isTrialActivated,
  saving
}) => {
  const theme = useTypedSelector(selectTheme);
  const isPurpleTheme = theme === ThemeOptions.Purple;
  const isZeroSubscription = tier === SUBSCRIPTION_TIER.ZERO;

  return (
    <div
      className={cn(styles.billingCard, {
        [styles.billingCardPurpleAndPopular]: isPurpleTheme && isPopular,
      })}
    >
      {isPopular && <PopularLabel className={styles.billingCardPopularLabel} />}
      <div className={styles.billingCardTitleWrapper}>
        <h2 className={styles.billingCardTitle}>{title}</h2>
        {saving && <span>{saving}</span>}
      </div>
      <div className={styles.billingCardTimePeriodAndPrice}>
        <span>&#36;{`${price}`}</span>
        {`/${timePeriod}`}
      </div>

      <Button
        className={styles.billingCardBtn}
        disabled={isLoading || getIsActiveBtn(tier)}
        onClick={onClick}
      >
        {isLoading ? (
          <div className={styles.billingCardBtnLoadingWrapper}>
            <Skeleton baseColor="#8961f3" />
          </div>
        ) : (
          <>
            {getIsActiveBtn(tier) && (
              <CheckConfirmationIcon className={styles.billingCardCheckIcon} />
            )}
            {getIsActiveBtn(tier)
              ? 'Current plan'
              : isZeroSubscription || isTrialActivated
              ? buttonText
              : 'Start 7-day free trial'}
          </>
        )}
      </Button>
      <div className={styles.billingCardBenefitsContent}>
        <ul className={styles.billingCardBenefitsList}>
          {benefits?.list.map(benefit =>
            benefit.subList ? (
              <li key={benefit.id} className={styles.billingCardBenefitWithSublist}>
                <div className={styles.billingCardBenefit}>
                  <CheckboxIconChecked
                    className={cn(styles.billingCardCheckbox, {
                      [styles.billingCardwhiteCheckbox]: benefit.isCheckboxWhite,
                      [styles.billingCardGrayCheckbox]: isZeroSubscription,
                    })}
                  />
                  {benefit.title}
                </div>
                <ul className={styles.billingCardBenefitSublist}>
                  {benefit.subList.map(subBenefit => (
                    <li key={subBenefit.id}>
                      <span>&#8226;</span> {subBenefit.title}
                    </li>
                  ))}
                </ul>
              </li>
            ) : (
              <li key={benefit.id} className={styles.billingCardBenefit}>
                <CheckboxIconChecked
                  className={cn(styles.billingCardCheckbox, {
                    [styles.billingCardwhiteCheckbox]: benefit.isCheckboxWhite,
                    [styles.billingCardGrayCheckbox]: isZeroSubscription,
                  })}
                />
                {benefit.title}
              </li>
            )
          )}
        </ul>
      </div>
    </div>
  );
};
