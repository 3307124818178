import React from 'react';

export const ForumIcon = () => {
  return (
    <svg
      width='22'
      height='23'
      viewBox='0 0 22 23'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <mask
        id='mask0_2573_39383'
        style={{ maskType: 'alpha' }}
        maskUnits='userSpaceOnUse'
        x='0'
        y='0'
        width='22'
        height='23'
      >
        <rect y='0.142578' width='22' height='22' fill='#D9D9D9' />
      </mask>
      <g mask='url(#mask0_2573_39383)'>
        <path
          d='M6.8881 16.0765C6.6551 16.0765 6.4598 15.9977 6.30218 15.8401C6.14456 15.6825 6.06576 15.4871 6.06576 15.2542V13.6095H16.7563V6.20833H18.401C18.634 6.20833 18.8293 6.28714 18.9869 6.44476C19.1445 6.60237 19.2233 6.79768 19.2233 7.03068V19.3659L15.9339 16.0765H6.8881ZM2.77637 15.2542V3.74129C2.77637 3.50829 2.85518 3.31299 3.01279 3.15537C3.17041 2.99775 3.36572 2.91895 3.59871 2.91895H14.2892C14.5222 2.91895 14.7175 2.99775 14.8751 3.15537C15.0328 3.31299 15.1116 3.50829 15.1116 3.74129V11.1424C15.1116 11.3754 15.0328 11.5707 14.8751 11.7283C14.7175 11.886 14.5222 11.9648 14.2892 11.9648H6.06576L2.77637 15.2542ZM13.4669 10.3201V4.56364H4.42106V10.3201H13.4669Z'
          fill='#989898'
        />
      </g>
    </svg>
  );
};
