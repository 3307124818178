import './index.scss';
import './prose.scss';

import { ArticleData, extract } from '@extractus/article-extractor';
import getVideoId from 'get-video-id';
import {
  ClipboardEvent,
  createRef,
  Dispatch,
  Fragment,
  MouseEvent,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useCountUp } from 'react-countup';
import Skeleton from 'react-loading-skeleton';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { v4 } from 'uuid';
import { TranscriptResponse } from 'youtube-transcript';

import { IPrompt } from '../../../interfaces';
import { ExtensionService } from '../../../service';
import {
  AlchemyModel,
  ConversationType,
  IConversation,
  IMessage,
  IMessageBase,
} from '../../../service/base/ai/interfaces';
import { PromptTemplate, PromptTemplates } from '../../../service/base/ai/prompts/templates';
import { WebPlatform, WebPlatformDetectionService } from '../../../service/base/platform';
import { IYouTubeVideoBaseInfo, YouTubeService } from '../../../service/base/platform/youtube';
import { IUploadItem, UploadStatus } from '../../../service/base/uploads';
import { useExtensionServiceContext } from '../../../service/context';
import { ExtensionEvents, useExtensionEventListener } from '../../../service/events';
import { ExtensionEventType } from '../../../service/events/types';
import { ITokensStatus } from '../../../service/interfaces';
import { TempAppMemory, TempAppMemoryKey } from '../../../service/temp/memory';
import { ExtensionStorage } from '../../../storage';
import { StorageKey } from '../../../storage/keys';
import { useAsyncProcessManagerContext } from '../../../tools/async/context';
import { useDebounce } from '../../../tools/events';
import { FileTools } from '../../../tools/file';
import { GraphicTools } from '../../../tools/graphics';
import { RequestTools } from '../../../tools/request';
import { StringTools } from '../../../tools/string';
import { PromptDetailsModal } from '../../components/auth/modal/prompt';
import ArrowRightAltIcon from '../../design/assets/svg/icons/ArrowRightAltIcon';
import AttachmentFileIcon from '../../design/assets/svg/icons/AttachmentFileIcon';
import { CloseIcon } from '../../design/assets/svg/icons/CloseIcon';
import { CloseIconAlt } from '../../design/assets/svg/icons/CloseIconAlt';
import DocumentIcon from '../../design/assets/svg/icons/DocumentIcon';
import { ForumIcon } from '../../design/assets/svg/icons/ForumIcon';
import { HashIcon } from '../../design/assets/svg/icons/HashIcon';
import { LightningIcon } from '../../design/assets/svg/icons/LightningIcon';
import { PromptLibraryIcon } from '../../design/assets/svg/icons/PromptLibraryIcon';
import ScreenshotIcon from '../../design/assets/svg/icons/ScreenshotIcon';
import SearchIconAlt from '../../design/assets/svg/icons/SearchIconAlt';
import WebIcon from '../../design/assets/svg/icons/WebIcon';
import YouTubeIcon from '../../design/assets/svg/icons/YouTubeIcon';
import { UpgradeReviews } from '../../design/assets/svg/UpgradeReviews';
import { Avatar } from '../../design/components/avatar';
import { Button } from '../../design/components/button';
import { LoadingOverlay, LoadingSpinner } from '../../design/components/loading';
import { CircleLoader } from '../../design/components/loading/progress/circle';
import { alert } from '../../design/components/modal/alert';
import { prompt as callPromptModal } from '../../design/components/modal/prompt';
import { SmoothVisibility } from '../../design/components/smoothVisibility';
import { Dialog } from './components/dialog';
import { FloatingDocuments } from './components/documents';
import { ProgressCircle } from './components/message/progress';
import { PromptTextarea } from './components/promptTextarea';

export interface IChatScreen {
  visible?: boolean;
  mode: 'default' | 'synth' | 'search';
  activeConversation: IConversation | null;
  setActiveConversation: Dispatch<SetStateAction<IConversation | null>>;
}

export interface IYouTubeState {
  videoId: string | null;
  videoBaseInfo: IYouTubeVideoBaseInfo | null;
  videoTranscript: TranscriptResponse[] | null;
}

export function ChatScreen({
  visible = true,
  mode,
  activeConversation,
  setActiveConversation,
}: IChatScreen) {
  const dialogWrapperRef = createRef<HTMLDivElement>();
  const extensionService = useExtensionServiceContext();
  const asyncProcessManager = useAsyncProcessManagerContext();

  const [url, setURL] = useState<string | null>(null);
  const [parsedURLData, setParsedURLData] = useState<ArticleData | null>(null);

  const [activeModel, setActiveModel] = useState<AlchemyModel>(AlchemyModel.Claude35Sonnet);

  const [currentMessageId, setCurrentMessageId] = useState<number | null>(null);

  const [prompt, setPrompt] = useState<string>('');
  const [platform, setPlatform] = useState<WebPlatform>(WebPlatform.None);
  const [youTubeState, setYouTubeState] = useState<IYouTubeState>({
    videoId: null,
    videoBaseInfo: null,
    videoTranscript: null,
  });

  const user = extensionService.useExtensionUser();
  const userTier = user.session?.tier ?? 0;

  const {
    items: attachments,
    upload: uploadAttachment,
    removeFile: removeAttachment,
    clearAll: clearAllAttachments,
  } = extensionService.useUploads({ maxFiles: 1, concurrentUploads: 1 });

  const [hideSuggestions, setHideSuggestions] = useState<boolean>(false);
  const [isPromptSending, setIsPromptSending] = useState<boolean>(false);
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [submittedMessage, setSubmittedMessage] = useState<IMessage | null>(null);
  const [renderingMessage, setRenderingMessage] = useState<IMessage | null>(null);
  const [abortController, setAbortController] = useState<AbortController | null>(null);

  const [isShowTranscript, setIsShowTranscript] = useState<boolean>(false);

  const [renderedMessages, setRenderedMessages] = useState<IMessage[]>([]);

  const [modalMessage, setModalMessage] = useState<IMessage | null>(null);
  const [floatingDocumentsVisible, setFloatingDocumentsVisible] = useState<boolean>(false);

  const modelCanGenerateImages = useMemo(
    () => [AlchemyModel.DALLE3].includes(activeModel),
    [activeModel]
  );

  const [lastMode, setLastMode] = useState<'default' | 'synth' | 'search'>(mode);

  useEffect(() => {
    if (mode !== lastMode) {
      setActiveConversation(null);
      setLastMode(mode);
    }
  }, [mode]);

  useEffect(() => {
    setParsedURLData(null);
  }, [url]);

  useDebounce(
    () => {
      if (url && mode === 'synth' && StringTools.isValidURL(url)) {
        asyncProcessManager.doProcess({
          name: 'Extract data for url',
          action: async () => {
            let result: ArticleData = {};

            try {
              const article = await extract(
                'https://corsproxy.io/?' + encodeURIComponent(StringTools.ensureHttps(url))
              );

              if (article) {
                result = article;
              } else {
                const html = await RequestTools.fetchHtmlContent(
                  'https://corsproxy.io/?' + encodeURIComponent(StringTools.ensureHttps(url))
                );

                if (html) {
                  const urlDocument = RequestTools.htmlStringToDocument(html);

                  result.title =
                    urlDocument.title.length === 0
                      ? GraphicTools.getPageTitle(urlDocument) ?? article.title
                      : urlDocument.title;
                  result.description =
                    GraphicTools.getPageTitle(urlDocument) ??
                    article.description ??
                    new URL(url).pathname;

                  result.content = html;
                  result.url = url;

                  result.image = GraphicTools.getPagePreviewImageURL(urlDocument) ?? undefined;
                }
              }
            } catch (e) {
              // pass
            }

            setParsedURLData(result);
          },
        });
      } else {
        setParsedURLData(null);
      }
    },
    [url],
    1000
  );

  const scrollChatToTheEnd = (smooth?: boolean, force?: boolean) => {
    const container = document?.querySelector('#root .dialog-wrapper');

    if (container) {
      const scrollPosition = container.scrollTop + container.clientHeight;
      const scrollThreshold = container.scrollHeight - 30;

      if (scrollPosition >= scrollThreshold || force) {
        container.scrollTo({
          top: container.scrollHeight + 100,
          behavior: smooth ? 'smooth' : undefined,
        });
      }
    }
  };

  const cleanupAfterRendering = () => {
    setSubmittedMessage(null);
    setRenderingMessage(null);
    setIsPromptSending(false);
    setAbortController(null);
    scrollChatToTheEnd(true);
  };

  const stopRendering = () => {
    abortController?.abort();
  };

  const addPlatformInfoToPrompt = async (promptText: string): Promise<string> => {
    if (hideSuggestions || [AlchemyModel.ChatPDF, AlchemyModel.DALLE3].includes(activeModel))
      return promptText;

    if (platform === WebPlatform.YouTube && youTubeState.videoId) {
      return PromptTemplates.fill(
        youTubeState.videoTranscript
          ? PromptTemplate.YouTubeVideoRelatedWithTranscript
          : PromptTemplate.YouTubeVideoRelated,
        {
          prompt: promptText,
          video: JSON.stringify(youTubeState.videoBaseInfo),
          transcript: JSON.stringify(youTubeState.videoTranscript),
        }
      );
    } else if (platform === WebPlatform.Other) {
      const article = await WebPlatformDetectionService.getCurrentPageArticle(url);

      return StringTools.truncate(
        'Hi. You must answer directly to user!\n\nHere is some related info\n\nUser have sended this prompt (please answer only to this prompt):\n' +
          promptText +
          '\n\nThis prompt is related to webpage' +
          JSON.stringify(article),
        1000000
      );
    } else {
      return promptText;
    }
  };

  const handleSend = async (
    customPrompt?: string,
    alias?: string,
    model?: AlchemyModel,
    customPlatform?: WebPlatform,
    customChatTitle?: string
  ) => {
    if (isPromptSending) return;

    const sendWithModel = model ?? activeModel;
    const sendWithPlatform = customPlatform ?? platform;

    await asyncProcessManager?.doProcess({
      name: 'Submit prompt',
      onError: () => {
        setActiveConversation(prev =>
          prev
            ? {
                ...prev,
                messages: [
                  ...(prev.messages ?? []),
                  ...(submittedMessage ? [submittedMessage] : []),
                ],
              }
            : null
        );

        setTimeout(() => {
          cleanupAfterRendering();
        }, 10);
      },
      action: async () => {
        setPrompt('');
        setIsPromptSending(true);

        const lastMessage =
          activeConversation !== null
            ? renderedMessages[renderedMessages.length - 1] ??
              activeConversation?.messages?.[activeConversation.messages.length - 1] ??
              null
            : null;

        const chatPDFFailure =
          sendWithModel === AlchemyModel.ChatPDF &&
          !activeConversation?.pdf_source_id &&
          attachments.length === 0 &&
          !renderedMessages.some(m => !!m.url && m.url.includes('.pdf'));

        const finalPrompt = !chatPDFFailure
          ? customPrompt ?? prompt
          : "PLEASE SAY TO ME THIS (BUT CHANGE A LITTLE BIT AND MAKE IT MORE KINDER AND DON'T SAY THAT I WANTED YOU TO DO THAT): Please upload PDF file";
        const finalAlias = !chatPDFFailure ? alias : alias ?? customPrompt ?? prompt;

        const userMessageBase: IMessageBase = {
          chat: activeConversation?.id ?? undefined,
          attachments: [],
          model_message: false,
          message: StringTools.truncate(finalPrompt, 1000000),
          alias: finalAlias ? StringTools.truncate(finalAlias, 1000000) : null,
          url:
            attachments.length > 0 && sendWithPlatform !== WebPlatform.AISearch
              ? attachments[0].url
              : null,
          previous_message: lastMessage?.id ?? null,
          platform:
            !hideSuggestions &&
            userTier >= 2 &&
            ((((sendWithPlatform === WebPlatform.YouTube && youTubeState.videoId) ||
              sendWithPlatform === WebPlatform.Other) &&
              !attachments.length) ||
              sendWithPlatform === WebPlatform.AISearch)
              ? {
                  type: sendWithPlatform,
                  title:
                    sendWithPlatform === WebPlatform.YouTube
                      ? youTubeState.videoBaseInfo?.title ?? 'YouTube Video'
                      : sendWithPlatform === WebPlatform.AISearch
                      ? 'AI Search'
                      : parsedURLData?.title ?? document.title,
                  author: youTubeState.videoBaseInfo?.author_name ?? 'Unknown author',
                  link: youTubeState.videoId
                    ? `https://www.youtube.com/watch?v=${youTubeState.videoId}`
                    : undefined,
                  thumbnail: youTubeState.videoBaseInfo?.thumbnail_url,
                }
              : { type: WebPlatform.None },
        };

        const userMessage: IMessage = {
          id: -2,
          ...userMessageBase,
          created_at: new Date().toISOString(),
        };

        setSubmittedMessage(userMessage);
        setTimeout(() => {
          scrollChatToTheEnd(true, true);
        }, 100);

        const newAbortController = new AbortController();

        setAbortController(newAbortController);

        let submittedMessageFreshState: IMessage | null = userMessage;
        let renderingMessageFreshState: IMessage | null = null;

        clearAllAttachments();

        await extensionService.ai?.generate({
          request: {
            model: chatPDFFailure ? AlchemyModel.Claude35Sonnet : sendWithModel,
            image_generation:
              [AlchemyModel.DALLE3].includes(sendWithModel) && attachments.length === 0,
            image_process: attachments.length > 0,
            create_chat: !activeConversation,
            chat: {
              chat_type: mode === 'synth' ? ConversationType.Synth : ConversationType.Chat,
              visible_chat: true,
              create_chat_model: chatPDFFailure ? AlchemyModel.ChatPDF : undefined,
              chat_title: !activeConversation
                ? customChatTitle ??
                  StringTools.truncate(
                    (
                      (!!userMessage.platform && userMessageBase.platform?.type !== WebPlatform.None
                        ? (userMessageBase.platform?.title ??
                            parsedURLData?.title ??
                            document.title) + ': '
                        : '') + (userMessageBase.alias ?? userMessageBase.message ?? '')
                    )?.trim(),
                    97
                  )
                : undefined,
            },
            save_message: true,
            regenerate: false,
            message: userMessageBase,
          },
          signal: newAbortController.signal,
          onCreatedChat: chat => setActiveConversation(chat),
          onCreatedUserMessage: message => {
            submittedMessageFreshState = message;
            setSubmittedMessage(submittedMessageFreshState);
            setCurrentMessageId(message.id);
            setTimeout(() => scrollChatToTheEnd(true, true), 100);
          },
          onCreatedAssistantMessage: message => {
            renderingMessageFreshState = message;
            setCurrentMessageId(message.id);
            setRenderingMessage(renderingMessageFreshState);
          },
          onGenerationChunkReceived: chunk => {
            if (chunk.message_id !== renderingMessageFreshState?.id) return;
            renderingMessageFreshState = {
              ...renderingMessageFreshState,
              message: (renderingMessageFreshState.message ?? '') + chunk.diff,
            };
            setRenderingMessage(renderingMessageFreshState);
            setTimeout(scrollChatToTheEnd, 10);
          },
          onImageGenerated: url => {
            renderingMessageFreshState = renderingMessageFreshState
              ? { ...renderingMessageFreshState, url }
              : null;
            setRenderingMessage(renderingMessageFreshState);
            setTimeout(scrollChatToTheEnd, 10);
          },
          onUpdatedAssistantMessage: message => {
            renderingMessageFreshState = message;
            setRenderingMessage(renderingMessageFreshState);
          },
          onGenerationError: error => {
            renderingMessageFreshState = renderingMessageFreshState
              ? { ...renderingMessageFreshState, error }
              : null;
            setRenderingMessage(renderingMessageFreshState);
          },
          onCompleted: () => {
            cleanupAfterRendering();
            setActiveConversation(prev =>
              prev
                ? {
                    ...prev,
                    messages: [
                      ...(prev.messages ?? []),

                      ...(submittedMessageFreshState ? [submittedMessageFreshState] : []),
                      ...(renderingMessageFreshState ? [renderingMessageFreshState] : []),
                    ],
                  }
                : null
            );
          },
          onAborted: () => {
            setActiveConversation(prev =>
              prev
                ? {
                    ...prev,
                    messages: [
                      ...(prev.messages ?? []),
                      ...(submittedMessageFreshState ? [submittedMessageFreshState] : []),
                      ...(renderingMessageFreshState ? [renderingMessageFreshState] : []),
                    ],
                  }
                : null
            );
            setTimeout(cleanupAfterRendering, 10);
          },
        });
      },
    });
  };

  const handleSuggestionSend = async (
    customPrompt?: string,
    alias?: string,
    model?: AlchemyModel,
    customPlatform?: WebPlatform
  ) => {
    if (userTier < 2) {
      ExtensionEvents.dispatch(ExtensionEventType.ShowUpgradeWindow);

      return;
    }

    return handleSend(
      customPrompt,
      alias,
      modelCanGenerateImages || activeModel === AlchemyModel.ChatPDF
        ? AlchemyModel.Claude35Sonnet
        : model,
      customPlatform
    );
  };

  const handleMessageEdit = async (message: IMessage, newText: string, customAlias?: string) => {
    if (isPromptSending) return;

    await asyncProcessManager?.doProcess({
      name: 'Submit edited prompt',
      onError: () => {
        setActiveConversation(prev =>
          prev
            ? {
                ...prev,
                messages: [
                  ...(prev.messages ?? []),
                  ...(submittedMessage ? [submittedMessage] : []),
                ],
              }
            : null
        );
        setTimeout(() => {
          cleanupAfterRendering();
        }, 10);
      },
      action: async () => {
        setPrompt('');
        setIsPromptSending(true);

        const userMessage: IMessage = {
          ...message,
          chat: activeConversation?.id,
          url: null,
          message: StringTools.truncate(newText, 1000000),
          alias: StringTools.truncate(customAlias ?? newText, 1000000),
        };

        setSubmittedMessage(userMessage);
        setTimeout(() => {
          scrollChatToTheEnd(true);
        }, 100);

        const newAbortController = new AbortController();

        setAbortController(newAbortController);

        let submittedMessageFreshState: IMessage | null = userMessage;
        let renderingMessageFreshState: IMessage | null = null;

        clearAllAttachments();

        await extensionService.ai?.generate({
          request: {
            model: activeModel,
            image_generation: [AlchemyModel.DALLE3].includes(activeModel),
            image_process: false,
            create_chat: false,
            save_message: true,
            regenerate: false,
            message: userMessage,
          },
          signal: newAbortController.signal,
          onCreatedChat: chat => setActiveConversation(chat),
          onCreatedUserMessage: message => {
            submittedMessageFreshState = message;
            setSubmittedMessage(submittedMessageFreshState);
            setCurrentMessageId(message.id);
            setTimeout(() => scrollChatToTheEnd(true), 100);
          },
          onCreatedAssistantMessage: message => {
            renderingMessageFreshState = message;
            setCurrentMessageId(message.id);
            setRenderingMessage(renderingMessageFreshState);
          },
          onGenerationChunkReceived: chunk => {
            if (chunk.message_id !== renderingMessageFreshState?.id) return;
            renderingMessageFreshState = {
              ...renderingMessageFreshState,
              message: (renderingMessageFreshState.message ?? '') + chunk.diff,
            };
            setRenderingMessage(renderingMessageFreshState);
            setTimeout(scrollChatToTheEnd, 10);
          },
          onImageGenerated: url => {
            renderingMessageFreshState = renderingMessageFreshState
              ? { ...renderingMessageFreshState, url }
              : null;
            setRenderingMessage(renderingMessageFreshState);
            setTimeout(scrollChatToTheEnd, 10);
          },
          onUpdatedAssistantMessage: message => {
            renderingMessageFreshState = message;
            setRenderingMessage(renderingMessageFreshState);
          },
          onGenerationError: error => {
            renderingMessageFreshState = renderingMessageFreshState
              ? { ...renderingMessageFreshState, error }
              : null;
            setRenderingMessage(renderingMessageFreshState);
          },
          onCompleted: () => {
            cleanupAfterRendering();
            setActiveConversation(prev =>
              prev
                ? {
                    ...prev,
                    messages: [
                      ...(prev.messages ?? []),
                      ...(submittedMessageFreshState ? [submittedMessageFreshState] : []),
                      ...(renderingMessageFreshState ? [renderingMessageFreshState] : []),
                    ],
                  }
                : null
            );
          },
          onAborted: () => {
            setActiveConversation(prev =>
              prev
                ? {
                    ...prev,
                    messages: [
                      ...(prev.messages ?? []),
                      ...(submittedMessageFreshState ? [submittedMessageFreshState] : []),
                      ...(renderingMessageFreshState ? [renderingMessageFreshState] : []),
                    ],
                  }
                : null
            );
            setTimeout(cleanupAfterRendering, 10);
          },
        });
      },
    });
  };

  const handleMessageSave = (message: IMessage) => {
    setModalMessage(message);
    setFloatingDocumentsVisible(true);
  };

  const handleMessageRegenerationRequest = async (message: IMessage) => {
    if (isPromptSending) return;

    await asyncProcessManager?.doProcess({
      name: 'Regenerate prompt',
      onError: () => {
        setTimeout(() => {
          cleanupAfterRendering();
        }, 10);
      },
      action: async () => {
        setPrompt('');
        setIsPromptSending(true);

        const userMessage: IMessage = {
          ...message,
          previous_message: message.id,
          chat: activeConversation?.id,
        };

        setTimeout(() => {
          scrollChatToTheEnd(true);
        }, 100);

        const newAbortController = new AbortController();

        setAbortController(newAbortController);

        let renderingMessageFreshState: IMessage | null = null;

        clearAllAttachments();

        await extensionService.ai?.generate({
          request: {
            model: activeModel,
            image_generation: [AlchemyModel.DALLE3].includes(activeModel),
            image_process: false,
            create_chat: false,
            save_message: true,
            message: userMessage,
            regenerate: true,
          },
          signal: newAbortController.signal,
          onCreatedChat: chat => setActiveConversation(chat),
          onCreatedAssistantMessage: message => {
            renderingMessageFreshState = message;
            setCurrentMessageId(message.id);
            setRenderingMessage(renderingMessageFreshState);
          },
          onGenerationChunkReceived: chunk => {
            if (chunk.message_id !== renderingMessageFreshState?.id) return;
            renderingMessageFreshState = {
              ...renderingMessageFreshState,
              message: (renderingMessageFreshState.message ?? '') + chunk.diff,
            };
            setRenderingMessage(renderingMessageFreshState);
            setTimeout(scrollChatToTheEnd, 10);
          },
          onImageGenerated: url => {
            renderingMessageFreshState = renderingMessageFreshState
              ? { ...renderingMessageFreshState, url }
              : null;
            setRenderingMessage(renderingMessageFreshState);
            setTimeout(scrollChatToTheEnd, 10);
          },
          onUpdatedAssistantMessage: message => {
            renderingMessageFreshState = message;
            setRenderingMessage(renderingMessageFreshState);
          },
          onGenerationError: error => {
            renderingMessageFreshState = renderingMessageFreshState
              ? { ...renderingMessageFreshState, error }
              : null;
            setRenderingMessage(renderingMessageFreshState);
          },
          onCompleted: () => {
            cleanupAfterRendering();
            setActiveConversation(prev =>
              prev
                ? {
                    ...prev,
                    messages: [
                      ...(prev.messages ?? []),
                      ...(renderingMessageFreshState ? [renderingMessageFreshState] : []),
                    ],
                  }
                : null
            );
          },
          onAborted: () => {
            setActiveConversation(prev =>
              prev
                ? {
                    ...prev,
                    messages: [
                      ...(prev.messages ?? []),
                      ...(renderingMessageFreshState ? [renderingMessageFreshState] : []),
                    ],
                  }
                : null
            );
          },
        });
      },
    });
  };

  const handleSendButtonClick = async (
    _?: MouseEvent<HTMLButtonElement>,
    customPromptText?: string,
    customPromptAlias?: string
  ) => {
    if (isPromptSending) {
      stopRendering();
    } else {
      const finalPrompt = customPromptText ?? prompt;

      if (finalPrompt.trim().length > 0)
        handleSend(await addPlatformInfoToPrompt(finalPrompt), customPromptAlias ?? finalPrompt);
    }
  };

  const handlePasteInTextArea = (e: ClipboardEvent<HTMLElement>) => {
    const file = Array.from(e.clipboardData.items)
      .find(item => item.type.includes('image'))
      ?.getAsFile();

    if (file) uploadAttachment(file);
  };

  const handleModelSelection = (model: AlchemyModel) => {
    setActiveModel(model);
    if (activeModel !== model) {
      if (isPromptSending) stopRendering();
      cleanupAfterRendering();
      setActiveConversation(null);
      clearAllAttachments();

      ExtensionStorage.setItem(StorageKey.NewChatModel, model);
    }

    if (activeConversation === null) {
      ExtensionStorage.setItem(StorageKey.NewChatModel, model);
    }
  };

  const handleScreenshotButtonClick = () => {
    if (document.querySelector('blob-app')) {
      ExtensionEvents.dispatch(ExtensionEventType.ScreenshotRequested);
    } else {
      alert({
        title: 'Notification',
        theme: 'blob',
        text: 'To use this feature you need to have Alchemy extension installed.',
      });
    }
  };

  const handleFileUploadButtonClick = async () => {
    const file = await FileTools.uploadFile(['png', 'jpeg', 'jpg', 'gif', 'pdf']);

    if (file) uploadAttachment(file);
  };

  const createNewChat = (customModel?: AlchemyModel) => {
    if (isPromptSending) {
      stopRendering();
      cleanupAfterRendering();
    }

    setPrompt('');
    setCurrentMessageId(null);
    setActiveConversation(null);

    if (customModel) {
      setActiveModel(customModel);
    }
  };

  const handleSearchInputSubmission = async (query: string) => {
    setIsPromptSending(true);
    setIsSearching(true);

    const searchResults = await extensionService.searchWeb(query);

    setIsSearching(false);

    setTimeout(() => {
      handleSend(
        PromptTemplates.fill(PromptTemplate.AISearch, {
          query,
          results: JSON.stringify(searchResults),
        }),
        query,
        AlchemyModel.Claude35Sonnet,
        WebPlatform.AISearch
      );
    }, 10);
  };

  const [tokensStatus, setTokensStatus] = useState<ITokensStatus | null>(null);

  useEffect(() => {
    asyncProcessManager?.doProcess({
      name: 'Fetch available tokens',
      action: async () => {
        if (userTier >= 2) {
          setTokensStatus(null);
        } else {
          const result = await extensionService.getTokensStatus();

          setTokensStatus(result);
        }
      },
    });
  }, [user.profile, user.session?.tier, activeConversation?.messages]);

  useEffect(() => {
    setTimeout(() => scrollChatToTheEnd(false, true), 10);
  }, [activeConversation?.id]);

  useEffect(() => {
    setActiveModel(activeConversation?.model ?? activeModel);
    setCurrentMessageId(
      activeConversation?.current_message_id ??
        // eslint-disable-next-line prefer-spread
        Math.max.apply(
          Math,
          (activeConversation?.messages ?? [{ id: -1 }])?.map(m => {
            return m.id;
          })
        )
    );
  }, [activeConversation]);

  useEffect(() => {
    const refreshPlatform = async (newUrl: string) => {
      if (isPromptSending || mode !== 'synth' || !url) {
        setPlatform(WebPlatform.None);

        return;
      }
      const currentPlatform = WebPlatformDetectionService.getActive(url);

      setPlatform(currentPlatform);

      if (currentPlatform === WebPlatform.YouTube) {
        const videoId = getVideoId(newUrl).id ?? null;

        setYouTubeState(prevState => ({
          ...prevState,
          videoId,
        }));

        if (videoId) {
          const baseInfo = await YouTubeService.getVideoBaseInfo(videoId);

          setYouTubeState(prevState => ({
            ...prevState,
            videoId,
            videoBaseInfo: baseInfo,
          }));

          const transcript = await YouTubeService.getTranscript(videoId);

          setYouTubeState(prevState => ({
            ...prevState,
            videoId,
            videoBaseInfo: baseInfo,
            videoTranscript: transcript,
          }));
        } else {
          setYouTubeState({
            videoId: null,
            videoBaseInfo: null,
            videoTranscript: null,
          });
        }
      }
    };

    refreshPlatform(url ?? '');
  }, [activeModel, user.session?.token, isPromptSending, url]);

  const allMessages = useMemo(
    () => [
      ...(activeConversation?.messages ?? []),
      ...(submittedMessage ? [submittedMessage] : []),
      ...(renderingMessage ? [{ ...renderingMessage, _generating: true }] : []),
    ],
    [activeConversation?.messages, submittedMessage, renderingMessage]
  );

  useEffect(() => {
    setIsShowTranscript(false);
  }, [hideSuggestions, isPromptSending, attachments]);

  // useExtensionEventListener(ExtensionEventType.SidebarSetPrompt, e => {
  //   if (typeof e.data !== 'string') return;
  //   setPrompt(e.data);
  // });

  // useExtensionEventListener(
  //   ExtensionEventType.PromptLibrarySubmission,
  //   e => {
  //     if (!e.data) return;

  //     handleSend(
  //       e.data.filledPrompt,
  //       e.data.alias,
  //       undefined,
  //       undefined,
  //       e.data.title ?? undefined
  //     );
  //   },
  //   [handleSend]
  // );

  useExtensionEventListener(
    ExtensionEventType.ScreenshotReady,
    e => {
      if (e.data) {
        const file = GraphicTools.dataURLtoFile(e.data, `alchemy-screenshot-${v4()}.png`);

        uploadAttachment(file);
      }
    },
    []
  );

  const suggestionsVisible = useMemo(
    () =>
      (platform === WebPlatform.YouTube &&
        !!youTubeState.videoBaseInfo?.title &&
        !hideSuggestions) ||
      (platform === WebPlatform.Other && !hideSuggestions) ||
      attachments.length > 0,
    [youTubeState.videoBaseInfo, activeModel, hideSuggestions, attachments, platform]
  );

  const textareaWrapperRef = createRef<HTMLDivElement>();
  const countUpRef = createRef<HTMLSpanElement>();
  const { update: updateCounter, start: startCounter } = useCountUp({
    ref: countUpRef,
    start: 0,
    end: tokensStatus?.tokens ?? 0,
    delay: 0,
    duration: 1,
  });

  useEffect(() => {
    if (!tokensStatus) return;

    if (tokensStatus?.tokens < tokensStatus.total_tokens) {
      updateCounter(tokensStatus?.tokens ?? 0);
      startCounter();
    }
  }, [startCounter, updateCounter, tokensStatus?.tokens]);

  const [panelPanelOffset, setPanelPanelOffset] = useState<number>(0);

  useEffect(() => {
    if (textareaWrapperRef.current instanceof HTMLDivElement) {
      const textarea = textareaWrapperRef.current.querySelector('textarea');

      const newOffset = (textarea?.style.height ? parseInt(textarea.style.height) : 114) - 114 + 70;

      setPanelPanelOffset(newOffset);
    }
  }, [textareaWrapperRef, prompt]);

  const [isShowUpgradePanel, setIsShowUpgradePanel] = useState<boolean>(userTier < 2);
  const hideUpgradePanel = async () => {
    await ExtensionStorage.setItem(
      StorageKey.UpgradePanelDismissDate,
      StringTools.getCurrentDate()
    );
    setIsShowUpgradePanel(false);
  };

  useEffect(() => {
    if (userTier >= 2) {
      setIsShowUpgradePanel(false);
    } else if (!isShowUpgradePanel) {
      asyncProcessManager?.doProcess({
        name: 'Check if upgrade panel must be visible',
        action: async () => {
          const dismissDate = ExtensionStorage.getItem(StorageKey.UpgradePanelDismissDate);

          if (!dismissDate || dismissDate !== StringTools.getCurrentDate()) {
            setIsShowUpgradePanel(true);
          }
        },
      });
    }
  }, [user]);

  const showUpgradePanel = attachments.length === 0 && !!tokensStatus && isShowUpgradePanel;

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [suggestedPrompts, setSuggestedPrompts] = useState<IPrompt[]>([]);
  const [isSuggestedPromptsLoading, setIsSuggestedPromptsLoading] = useState<boolean>(true);

  const [modalPrompt, setModalPrompt] = useState<IPrompt | null>(null);
  const [isPromptModalVisible, setIsPromptModalVisible] = useState<boolean>(false);

  const navigate = useNavigate();

  useEffect(() => {
    const promptToSend = TempAppMemory.read(TempAppMemoryKey.PromptToSend);

    if (promptToSend) {
      handleSend(promptToSend?.text ?? '', promptToSend?.alias ?? '');

      TempAppMemory.write(TempAppMemoryKey.PromptToSend, null);
    }
  }, [visible]);
  useEffect(() => {
    if (!user.session.tier) return;

    asyncProcessManager.doProcess({
      name: 'Fetch suggested prompts',
      action: async () => {
        setIsSuggestedPromptsLoading(true);

        const prompts =
          ((await extensionService.getSuggestedPrompts({ params: {} }))?.results as IPrompt[]) ??
          [];

        setSuggestedPrompts(
          prompts
            .map(value => ({ value, sort: Math.random() }))
            .sort((a, b) => a.sort - b.sort)
            .map(({ value }) => value)
            .slice(0, 3)
        );

        setIsSuggestedPromptsLoading(false);
      },
    });
  }, [user.session.tier]);

  return (
    <>
      <SmoothVisibility className="smooth-section" visible={visible}>
        <header>
          <div className="header-right-side-wrapper">
            <SmoothVisibility
              as="button"
              visible={!!activeConversation}
              className="new-btn"
              onClick={async () => {
                const model = await ExtensionStorage.getItem(StorageKey.NewChatModel);

                if (model && Object.values(AlchemyModel).includes(model as any)) {
                  createNewChat(model as any);
                } else {
                  createNewChat();
                }
              }}
            >
              + New chat
            </SmoothVisibility>
            <Button
              onClick={() => {
                navigate('/chat/history');
              }}
            >
              <ForumIcon />
            </Button>
          </div>
        </header>
        <div
          ref={dialogWrapperRef}
          className={`dialog-wrapper${suggestionsVisible ? ' suggestions-visible' : ''}${
            attachments.length > 0 ? ' suggestions-has-attachments' : ''
          }`}
        >
          <Dialog
            noBranding={true}
            model={activeModel}
            onRenderedMessages={messages => setRenderedMessages(messages)}
            messages={allMessages as any}
            currentMessageId={currentMessageId ?? undefined}
            onEditMessage={handleMessageEdit}
            isPromptSending={isPromptSending}
            onRegenerateMessage={async message => await handleMessageRegenerationRequest(message)}
            onMoveToChat={() => {
              asyncProcessManager?.doProcess({
                name: 'Move to chat',
                onError: () => {
                  setIsLoading(false);

                  return undefined;
                },
                action: async () => {
                  if (activeConversation) {
                    // setViewMode(SidebarViewMode.Chat);

                    const currentActiveConversation = {
                      ...activeConversation,
                      chat_type: ConversationType.Chat,
                    };

                    setIsLoading(true);

                    await extensionService.changeConversationType(
                      currentActiveConversation.id,
                      ConversationType.Chat
                    );

                    setIsLoading(false);

                    setActiveConversation(currentActiveConversation);
                  }
                },
              });
            }}
            synthModeActive={false}
            onSaveMessage={handleMessageSave}
          />
        </div>
        <SmoothVisibility
          visible={!!activeConversation || isPromptSending}
          className={'bottom-bar-wrapper'}
        >
          <div
            style={{ '--app-panel-offset': `${panelPanelOffset}px` } as any}
            className="bottom-bar"
          >
            <SmoothVisibility
              visible={suggestionsVisible}
              className={`floating-panel${
                attachments.length > 0 && !isShowTranscript ? ' with-attachments' : ''
              }${isShowTranscript ? ' transcript-visible' : ''}${
                platform === WebPlatform.YouTube &&
                !isShowTranscript &&
                attachments.length === 0 &&
                !hideSuggestions
                  ? ' youtube-big'
                  : ''
              }`}
            >
              <div
                className={'alchemy-row main-bar-row'}
                onClick={e => {
                  if (e.target instanceof HTMLElement && !e.target.closest('.close-button'))
                    if (platform === WebPlatform.YouTube && !hideSuggestions) {
                      if (userTier >= 2) {
                        setIsShowTranscript(prev => !prev);
                      } else {
                        ExtensionEvents.dispatch(ExtensionEventType.ShowUpgradeWindow);
                      }
                    }
                }}
              >
                {attachments.length > 0 && !isShowTranscript ? (
                  <div className="attachments">
                    {attachments.map((attachment: IUploadItem, index: number) => {
                      const uploadStatus = (
                        <div
                          onClick={async () => {
                            if (
                              attachment.status !== UploadStatus.Uploading &&
                              attachment.mime.startsWith('image')
                            )
                              await GraphicTools.viewImage(attachment.preview);
                          }}
                          className={`attachment-status ${attachment.status}`}
                        >
                          {attachment.status === 'uploading' ? (
                            attachment.uploadPercent === 100 ? (
                              <LoadingSpinner width={20} color="white" />
                            ) : (
                              <CircleLoader progress={attachment.uploadPercent ?? 0} />
                            )
                          ) : null}
                          {attachment.error && <span>Error</span>}
                        </div>
                      );

                      return (
                        <Fragment key={index}>
                          <div
                            className={
                              'attachment-item ' +
                              (attachment.mime.startsWith('image') ? 'image-item' : 'file-item')
                            }
                          >
                            {attachment.mime.startsWith('image') ? (
                              <div
                                className="attachment-image"
                                style={{
                                  backgroundImage: `url(${attachment.preview})`,
                                }}
                              />
                            ) : (
                              <div className="attachment-file">
                                <div className="attachment-icon">
                                  <DocumentIcon />
                                  {uploadStatus}
                                </div>

                                <div className="attachment-meta">
                                  <span className="title">{attachment.filename}</span>
                                  <span className="filetype">
                                    {StringTools.getFileTypeInfo(attachment.filename)?.title}
                                  </span>
                                </div>
                              </div>
                            )}

                            {attachment.mime.startsWith('image') && uploadStatus}

                            <div className="image-item-btn-wrapper">
                              <button
                                className="attachment-delete"
                                onClick={() => removeAttachment(attachment.id)}
                              >
                                <CloseIcon color="white" />
                              </button>
                            </div>
                          </div>
                        </Fragment>
                      );
                    })}
                  </div>
                ) : !hideSuggestions ? (
                  <>
                    {platform === WebPlatform.YouTube ? (
                      <>
                        <YouTubeIcon color="var(--color-titanium-400)" />
                        <p>{youTubeState.videoBaseInfo?.title}</p>
                      </>
                    ) : (
                      <>
                        <WebIcon color="var(--color-titanium-400)" />
                        <p>{parsedURLData?.title}</p>
                      </>
                    )}
                  </>
                ) : null}
                <button
                  onClick={() =>
                    !isShowTranscript
                      ? attachments.length > 0
                        ? clearAllAttachments()
                        : (() => {
                            const synthModeActive = false;

                            if (synthModeActive) {
                              // setViewMode(SidebarViewMode.Chat);
                              // ExtensionEvents.dispatch(ExtensionEventType.SidebarCreateNewChat);
                            } else {
                              setHideSuggestions(true);
                            }
                          })()
                      : setIsShowTranscript(false)
                  }
                  className="close-button"
                >
                  <CloseIconAlt color="var(--color-titanium-400)" />
                </button>
              </div>
              <SmoothVisibility
                className="transcript-toggler-wrapper"
                visible={!isShowTranscript && platform === WebPlatform.YouTube && !hideSuggestions}
              >
                <button
                  onClick={() => {
                    if (userTier >= 2) {
                      setIsShowTranscript(true);
                    } else {
                      ExtensionEvents.dispatch(ExtensionEventType.ShowUpgradeWindow);
                    }
                  }}
                  className="transcript-toggler"
                >
                  Show transcript
                </button>
              </SmoothVisibility>
              <SmoothVisibility visible={!isShowTranscript} className="alchemy-row suggestions">
                {attachments.length > 0 ? (
                  <>
                    {attachments[0].mime.startsWith('image') ? (
                      <>
                        <button
                          onClick={() => handleSend('Describe the image')}
                          className="suggestion"
                        >
                          <span>Describe the image</span>
                          <ArrowRightAltIcon />
                        </button>
                        <button
                          onClick={() => handleSend('Extract text from image')}
                          className="suggestion"
                        >
                          <span>Extract text</span>
                          <ArrowRightAltIcon />
                        </button>
                      </>
                    ) : (
                      <>
                        <button onClick={() => handleSend('Describe file')} className="suggestion">
                          <span>Describe file</span>
                          <ArrowRightAltIcon />
                        </button>
                        <button onClick={() => handleSend('Extract text')} className="suggestion">
                          <span>Extract text</span>
                          <ArrowRightAltIcon />
                        </button>
                      </>
                    )}
                  </>
                ) : platform === WebPlatform.YouTube && !hideSuggestions ? (
                  <>
                    <button
                      onClick={() =>
                        handleSuggestionSend(
                          youTubeState.videoBaseInfo
                            ? youTubeState.videoTranscript
                              ? PromptTemplates.fill(PromptTemplate.SummarizeVideoWithTranscript, {
                                  video: youTubeState.videoBaseInfo.title,
                                  transcript: JSON.stringify(youTubeState.videoTranscript),
                                })
                              : PromptTemplates.fill(PromptTemplate.SummarizeVideo, {
                                  video: youTubeState.videoBaseInfo.title,
                                })
                            : 'Say me that there is problem with current opened video',
                          'Summarize'
                        )
                      }
                      className="suggestion"
                    >
                      <span>Summarize content</span>
                      <ArrowRightAltIcon />
                    </button>
                    <button
                      onClick={() =>
                        handleSuggestionSend(
                          youTubeState.videoBaseInfo
                            ? youTubeState.videoTranscript
                              ? PromptTemplates.fill(
                                  PromptTemplate.TimeStampedSummaryWithTranscript,
                                  {
                                    video: youTubeState.videoBaseInfo.title,
                                    transcript: JSON.stringify(youTubeState.videoTranscript),
                                  }
                                )
                              : PromptTemplates.fill(PromptTemplate.TimeStampedSummary, {
                                  video: youTubeState.videoBaseInfo.title,
                                })
                            : 'Say me that there is problem with current opened video',
                          'Timestamped summary'
                        )
                      }
                      className="suggestion"
                    >
                      <span>Timestamped summary</span>
                      <ArrowRightAltIcon />
                    </button>
                    <button
                      onClick={() =>
                        handleSuggestionSend(
                          youTubeState.videoBaseInfo
                            ? PromptTemplates.fill(PromptTemplate.SummarizeVideoComments, {
                                video: youTubeState.videoBaseInfo.title,
                                transcript: JSON.stringify(youTubeState.videoTranscript),
                                comments: Array.from(
                                  document.querySelectorAll('ytd-comment-view-model')
                                ).map((c: any) => c.innerText),
                              })
                            : 'Say me that there is problem with current opened video',
                          'Summarize comments'
                        )
                      }
                      className="suggestion"
                    >
                      <span>Summarize comments</span>
                      <ArrowRightAltIcon />
                    </button>
                  </>
                ) : platform === WebPlatform.Other ? (
                  <>
                    <button
                      onClick={async () =>
                        handleSuggestionSend(
                          await addPlatformInfoToPrompt(
                            PromptTemplates.fill(PromptTemplate.Summarize, {})
                          ),
                          'Summarize'
                        )
                      }
                      className="suggestion"
                    >
                      <span>Summarize</span>
                      <ArrowRightAltIcon />
                    </button>
                    <button
                      onClick={async () =>
                        handleSuggestionSend(
                          await addPlatformInfoToPrompt(
                            PromptTemplates.fill(PromptTemplate.ExtractFormula, {})
                          ),
                          'Extract formula'
                        )
                      }
                      className="suggestion"
                    >
                      <span>Extract formula</span>
                      <ArrowRightAltIcon />
                    </button>
                  </>
                ) : null}
              </SmoothVisibility>

              <SmoothVisibility className="video-transcript" visible={isShowTranscript}>
                {youTubeState.videoTranscript?.map((item, index) => (
                  <span className="transcript-item" key={index}>
                    {item.text.replace(/&amp;#(\d+);/g, (_, dec) => String.fromCharCode(dec))}
                  </span>
                ))}
              </SmoothVisibility>
            </SmoothVisibility>
            {tokensStatus && userTier <= 2 && (
              <div className="floating-tokens-info">
                <div className="alchemy-row main-bar-row">
                  <div className="tokens-info">
                    <ProgressCircle
                      color="rgba(253, 170, 164, 1)"
                      title={
                        tokensStatus.tokens >= tokensStatus.total_tokens ? (
                          <span>All tokens used</span>
                        ) : (
                          <>
                            <span ref={countUpRef}>{tokensStatus.tokens ?? 0}</span>
                            <span className="slash"> / </span>
                            <span>{tokensStatus.total_tokens}</span>
                          </>
                        )
                      }
                      progress={Math.min(
                        (tokensStatus.tokens / tokensStatus.total_tokens) * 100,
                        99
                      )}
                    />
                  </div>
                  <button
                    onClick={() =>
                      ExtensionEvents.dispatch(ExtensionEventType.ShowUpgradePriceWindow)
                    }
                    className="close-button upgrade"
                  >
                    Get Unlimited
                  </button>
                </div>
              </div>
            )}
            <SmoothVisibility visible={showUpgradePanel} className="floating-upgrade-panel">
              <h4>Upgrade to become a true synthesizer</h4>
              <p>
                Get an early bird discount of 40% and start gaining your edge to create content.
              </p>
              <UpgradeReviews />
              <div className="upgrade-footer">
                <Button onClick={hideUpgradePanel} size="mini" text="Dismiss" />
                <Button
                  onClick={() =>
                    ExtensionEvents.dispatch(ExtensionEventType.ShowUpgradePriceWindow)
                  }
                  size="mini"
                  text="Upgrade"
                  active={true}
                />
              </div>
            </SmoothVisibility>
            <div className="floating-buttons">
              <SmoothVisibility visible={true} className={`floating-buttons-left`}>
                <button onClick={handleFileUploadButtonClick}>
                  <AttachmentFileIcon />
                </button>
                <button onClick={handleScreenshotButtonClick}>
                  <ScreenshotIcon />
                </button>
              </SmoothVisibility>
              <div className="floating-buttons-right">
                <button
                  onClick={() => {
                    // setViewMode(SidebarViewMode.Library)
                  }}
                >
                  <PromptLibraryIcon />
                </button>
                <button
                  onClick={() => {
                    toast('show quick prompts');
                  }}
                >
                  <LightningIcon />
                </button>
                <button
                  onClick={() => {
                    navigate('/snippets');
                  }}
                >
                  <HashIcon />
                </button>
              </div>
            </div>
            <PromptTextarea
              containerRef={textareaWrapperRef}
              onPaste={handlePasteInTextArea}
              conversationModel={activeModel}
              isPromptSending={isPromptSending}
              onSend={(e, customPromptText, customPromptAlias) => {
                if (!e && isPromptSending) return;
                handleSendButtonClick(e, customPromptText, customPromptAlias);
              }}
              prompt={prompt}
              setConversationModel={handleModelSelection}
              setPrompt={setPrompt}
              modelDropdownVerticalPosition="top"
              hideModels={false}
              maxRows={7}
            />
            <FloatingDocuments
              visible={floatingDocumentsVisible}
              onCancel={() => {
                setFloatingDocumentsVisible(false);
              }}
              onCreateNewDocument={() => {
                callPromptModal({
                  title: 'Create new document',
                  inputPlaceholder: 'Document name',
                  confirmButtonText: 'Create',

                  className: 'create-new-document-modal',

                  primary: true,
                  handleConfirm: async name => {
                    setFloatingDocumentsVisible(false);

                    await asyncProcessManager?.doProcess({
                      name: 'Create new document',
                      action: async () => {
                        await extensionService.createDocument({
                          name,
                          file: modalMessage?.message ?? '',
                        });

                        toast.success('Document created');

                        setModalMessage(null);

                        // ExtensionEvents.dispatch(ExtensionEventType.RefreshDocuments);
                      },
                    });
                  },
                });
              }}
              onReplaceExistingDocument={document => {
                asyncProcessManager?.doProcess({
                  name: 'Replace existing document',
                  action: async () => {
                    setFloatingDocumentsVisible(false);

                    const formData = new FormData();

                    formData.append('file', modalMessage?.message ?? '');
                    formData.append('name', document.name ?? '');

                    await extensionService.replaceExistingDocument({
                      id: document.id,
                      body: formData,
                    });

                    toast.success('Document replaced');

                    setModalMessage(null);

                    // ExtensionEvents.dispatch(ExtensionEventType.RefreshDocuments);
                  },
                });
              }}
            />
          </div>
        </SmoothVisibility>
        <SmoothVisibility className="chat-intro" visible={!activeConversation && !isPromptSending}>
          {mode === 'default' ? (
            <>
              <h1>Make your magic with Alchemy!</h1>
              <PromptTextarea
                conversationModel={activeModel}
                isPromptSending={isPromptSending}
                onSend={(e, customPromptText, customPromptAlias) => {
                  if (!e && isPromptSending) return;
                  handleSendButtonClick(e, customPromptText, customPromptAlias);
                }}
                prompt={prompt}
                setConversationModel={handleModelSelection}
                setPrompt={setPrompt}
                modelDropdownVerticalPosition="top"
                hideModels={false}
                maxRows={7}
              />

              <div className="suggested-prompts-wrapper">
                <div className="title">Suggested workflows</div>
                <div className="items">
                  {isSuggestedPromptsLoading ? (
                    <>
                      <Skeleton />
                      <Skeleton />
                      <Skeleton />
                    </>
                  ) : (
                    suggestedPrompts.map(item => (
                      <div
                        onClick={() => {
                          if (item.inputs) {
                            setModalPrompt(item);
                            setIsPromptModalVisible(true);
                          } else {
                            handleSend(item.prompt_template);
                          }
                        }}
                        className="item"
                        key={item.id}
                      >
                        <span className="item-title">{item.name}</span>
                        <span className="item-description">
                          {StringTools.truncate(item.description, 200)}
                        </span>
                      </div>
                    ))
                  )}
                </div>
              </div>
            </>
          ) : mode === 'synth' ? (
            <>
              <h1 className={url && StringTools.isValidURL(url ?? '') ? 'small' : ''}>
                Turn knowledge into formulas
              </h1>
              <div className="synth-data-wrapper">
                <input
                  placeholder="Add a website or YouTube link"
                  value={url}
                  className=""
                  onChange={e => setURL(e.target.value)}
                />
                <div className="disclaimer">Synthesize uses Claude Sonnet 3.5</div>

                <SmoothVisibility
                  className="website-suggestions-wrapper"
                  visible={!!url && StringTools.isValidURL(url ?? '')}
                >
                  {(!parsedURLData || (!!parsedURLData && !!parsedURLData.title)) &&
                  StringTools.isValidURL(url ?? '') ? (
                    <div className="website-info">
                      <>
                        {parsedURLData ? (
                          <>
                            <Avatar
                              url={parsedURLData.image ?? null}
                              size="big"
                              userName={parsedURLData.title?.slice(0, 1) ?? parsedURLData.url}
                            />

                            <div className="info-group">
                              <div className="title">
                                {StringTools.truncate(parsedURLData.title, 80)}
                              </div>
                              <div className="description">
                                {StringTools.truncate(parsedURLData.description, 100)}
                              </div>
                            </div>
                          </>
                        ) : (
                          <Skeleton />
                        )}
                      </>
                    </div>
                  ) : StringTools.isValidURL(url ?? '') ? (
                    <div className="error">Failed to fetch website data.</div>
                  ) : null}

                  {parsedURLData && parsedURLData.title && (
                    <div className="website-suggestions">
                      <div className="section-title">Suggested actions</div>

                      <div className="suggestions">
                        {platform === WebPlatform.YouTube && !hideSuggestions ? (
                          <>
                            <button
                              onClick={() =>
                                handleSuggestionSend(
                                  youTubeState.videoBaseInfo
                                    ? youTubeState.videoTranscript
                                      ? PromptTemplates.fill(
                                          PromptTemplate.SummarizeVideoWithTranscript,
                                          {
                                            video: youTubeState.videoBaseInfo.title,
                                            transcript: JSON.stringify(
                                              youTubeState.videoTranscript
                                            ),
                                          }
                                        )
                                      : PromptTemplates.fill(PromptTemplate.SummarizeVideo, {
                                          video: youTubeState.videoBaseInfo.title,
                                        })
                                    : 'Say me that there is problem with current opened video',
                                  'Summarize'
                                )
                              }
                              className="suggested-action"
                            >
                              <span>Summarize content</span>
                              <ArrowRightAltIcon />
                            </button>
                            <button
                              onClick={() =>
                                handleSuggestionSend(
                                  youTubeState.videoBaseInfo
                                    ? youTubeState.videoTranscript
                                      ? PromptTemplates.fill(
                                          PromptTemplate.TimeStampedSummaryWithTranscript,
                                          {
                                            video: youTubeState.videoBaseInfo.title,
                                            transcript: JSON.stringify(
                                              youTubeState.videoTranscript
                                            ),
                                          }
                                        )
                                      : PromptTemplates.fill(PromptTemplate.TimeStampedSummary, {
                                          video: youTubeState.videoBaseInfo.title,
                                        })
                                    : 'Say me that there is problem with current opened video',
                                  'Timestamped summary'
                                )
                              }
                              className="suggested-action"
                            >
                              <span>Timestamped summary</span>
                              <ArrowRightAltIcon />
                            </button>
                          </>
                        ) : platform === WebPlatform.Other ? (
                          <>
                            <button
                              onClick={async () =>
                                handleSuggestionSend(
                                  await addPlatformInfoToPrompt(
                                    PromptTemplates.fill(PromptTemplate.Summarize, {})
                                  ),
                                  'Summarize'
                                )
                              }
                              className="suggested-action"
                            >
                              <span>Summarize</span>
                              <ArrowRightAltIcon />
                            </button>
                            <button
                              onClick={async () =>
                                handleSuggestionSend(
                                  await addPlatformInfoToPrompt(
                                    PromptTemplates.fill(PromptTemplate.ExtractFormula, {})
                                  ),
                                  'Extract formula'
                                )
                              }
                              className="suggested-action"
                            >
                              <span>Extract formula</span>
                              <ArrowRightAltIcon />
                            </button>
                          </>
                        ) : null}
                      </div>
                    </div>
                  )}
                </SmoothVisibility>
              </div>
            </>
          ) : mode === 'search' ? (
            <>
              <>
                <h1>The world is your oyster</h1>
                <PromptTextarea
                  conversationModel={activeModel}
                  isPromptSending={isPromptSending}
                  onSend={(e, customPromptText, customPromptAlias) => {
                    if (!e && isPromptSending) return;

                    handleSearchInputSubmission(prompt);
                  }}
                  prompt={prompt}
                  setConversationModel={handleModelSelection}
                  setPrompt={setPrompt}
                  modelDropdownVerticalPosition="top"
                  hideModels={true}
                  maxRows={7}
                  placeholder="Search for anything"
                />

                <div className="search-suggestions-wrapper">
                  <div className="search-suggestions">
                    <div className="section-title">Suggested searches</div>

                    <div className="suggestions">
                      <button
                        onClick={() => handleSearchInputSubmission('Latest marketing trends')}
                        className="suggested-action"
                      >
                        <SearchIconAlt />
                        Latest marketing trends
                      </button>
                      <button
                        onClick={() => handleSearchInputSubmission('Top content creators in 2024')}
                        className="suggested-action"
                      >
                        <SearchIconAlt />
                        Top content creators in 2024
                      </button>
                      <button
                        onClick={() => handleSearchInputSubmission('Summarize the latest AI news')}
                        className="suggested-action"
                      >
                        <SearchIconAlt />
                        Summarize the latest AI news
                      </button>
                      <button
                        onClick={() => handleSearchInputSubmission('Top performing LLMs today')}
                        className="suggested-action"
                      >
                        <SearchIconAlt />
                        Top performing LLMs today
                      </button>
                    </div>
                  </div>
                </div>
              </>
            </>
          ) : null}
        </SmoothVisibility>
        <LoadingOverlay active={isLoading || isSearching} />
      </SmoothVisibility>
      <PromptDetailsModal
        visible={isPromptModalVisible}
        prompt={modalPrompt}
        onClose={() => {
          setIsPromptModalVisible(false);
          setTimeout(() => {
            setModalPrompt(null);
          }, 300);
        }}
        onPromptUpdate={(newState, update) => {
          asyncProcessManager.doProcess({
            name: 'Update prompt',
            action: async () => {
              if (update === 'favorite') {
                await extensionService.setFavorite({
                  favourite: newState.is_favourite,
                  prompt_id: newState.id,
                });
              } else if (update === 'command') {
                await extensionService.addCustomCommand({
                  prompt_pk: newState.id,
                  prompt_type: 2,
                  command: newState.name,
                  is_add: newState.is_in_command,
                });
              }
            },
          });
        }}
        onClickSendPrompt={function (inputs: { [key: string]: string }): void {
          if (!modalPrompt) return;

          const promptText = ExtensionService.fillPromptTemplate(modalPrompt, inputs);
          const promptAlias = ExtensionService.generatePromptAlias(modalPrompt, inputs);

          handleSend(promptText, promptAlias);
        }}
        isCustomCategory={false}
      />
    </>
  );
}
