export interface IBlobLogo {
  customOpacity?: number;
}
export default function BlobLogo(props: IBlobLogo) {
  return (
    <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.313 1.8782C13.6824 2.10026 15.2334 4.03438 16.7738 5.66208C18.3644 7.3429 20.1955 9.06274 20.1451 11.2113C20.0918 13.4848 18.563 15.516 16.5115 16.8548C14.5065 18.1632 11.9357 18.4776 9.40724 18.2187C6.83008 17.9549 4.06181 17.3132 2.56027 15.4263C1.11163 13.606 1.60797 11.2312 2.16164 9.11386C2.63602 7.29975 3.82949 5.8312 5.39251 4.59538C7.10771 3.23923 8.97348 1.65892 11.313 1.8782Z"
        stroke="var(--app-logo)"
        strokeOpacity={props.customOpacity ?? '0.8'}
        strokeWidth="1.88362"
      />
      <ellipse
        cx="10.7775"
        cy="8.80089"
        rx="1.2287"
        ry="1.33019"
        fill="var(--app-logo)"
        fillOpacity={props.customOpacity ?? '0.8'}
      />
      <ellipse
        cx="14.7228"
        cy="8.80089"
        rx="1.2287"
        ry="1.33019"
        fill="var(--app-logo)"
        fillOpacity={props.customOpacity ?? '0.8'}
      />
    </svg>
  );
}
