import { Theme, toast, ToastPosition } from 'react-toastify';

import { ErrorToast } from '../components/Notification/Error';
import { SuccessToast } from '../components/Notification/Success/SuccessToast';
import styles from '../components/Notification/Success/SuccessToast.module.scss';

export const settingNotification = {
  position: 'top-center' as ToastPosition,
  autoClose: 2000,
  hideProgressBar: false,
  newestOnTop: false,
  closeOnClick: false,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  theme: 'colored' as Theme,
};

export type ErrorNode = {
  data: {
    message: string;
    statusCode: number;
  };
  status: number | string;
  originalStatus?: number;
};

export const handleError = (err: string) =>
  toast(<ErrorToast text={err} />, {
    className: styles.wrapperNotification,
    style: { backgroundColor: 'transparent' },
  });
export const handleInfo = (info: string) => toast.info(info);

export const handleSuccess = (success: string) =>
  toast(<SuccessToast text={success} />, {
    className: styles.wrapperNotification,
    style: { backgroundColor: 'rgba(255, 255, 255, 0)' },
  });
