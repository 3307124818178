import './index.scss';

import { createRef, useState } from 'react';
import { obsidian as codeTheme } from 'react-syntax-highlighter/dist/cjs/styles/hljs';
import ReactTextareaAutosize from 'react-textarea-autosize';
import { toast } from 'react-toastify';

import { AlchemyModel, IMessage } from '../../../../../service/base/ai/interfaces';
import { WebPlatform } from '../../../../../service/base/platform';
import { useExtensionServiceContext } from '../../../../../service/context';
import { GraphicTools } from '../../../../../tools/graphics';
import { StringTools } from '../../../../../tools/string';
import BlobLogo from '../../../../design/assets/svg/BlobLogo';
import ArrowLeftIcon from '../../../../design/assets/svg/icons/ArrowLeftIcon';
import ArrowRightIcon from '../../../../design/assets/svg/icons/ArrowRightIcon';
import BlobSidebarChatsIcon from '../../../../design/assets/svg/icons/BlobSidebarChatsIcon';
import BlobSidebarSnippetsIcon from '../../../../design/assets/svg/icons/BlobSidebarSnippetsIcon';
import CopyIconChat from '../../../../design/assets/svg/icons/CopyIconChat';
import DocumentPlusIcon from '../../../../design/assets/svg/icons/DocumentPlusIcon';
import EditIcon from '../../../../design/assets/svg/icons/EditIcon';
import MessageErrorIcon from '../../../../design/assets/svg/icons/MessageErrorIcon';
import RefreshIcon from '../../../../design/assets/svg/icons/RefreshIcon';
import SearchIconAlt from '../../../../design/assets/svg/icons/SearchIconAlt';
import WebIcon from '../../../../design/assets/svg/icons/WebIcon';
import YouTubeIcon from '../../../../design/assets/svg/icons/YouTubeIcon';
import { Avatar } from '../../../../design/components/avatar';
import { SmoothText } from '../../../../design/components/smoothText';
import { SmoothVisibility } from '../../../../design/components/smoothVisibility';
import { MessageAttachment } from './attachment';
import { MessageImage } from './image';
import { ProgressCircle } from './progress';

interface MessageProps {
  model?: AlchemyModel;
  message: IMessage;
  showMoveToChatButton?: boolean;
  noEdit?: boolean;
  onChangeVersion?: (version: number) => void;
  onMoveToChat?: () => void;
  onSave: (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onRetry: (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onEdit: (text: string) => void;
  onSnippet: () => void;
}

export const Message = (props: MessageProps) => {
  const [copied, setCopied] = useState(false);

  const extensionService = useExtensionServiceContext();
  const extensionUser = extensionService.useExtensionUser();

  const handleCopy = (content: string) => {
    navigator.clipboard.writeText(content).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    });
  };

  const firstAndLastName =
    (extensionUser.profile?.first_name ?? 'User') +
    (extensionUser.profile?.last_name ? ' ' + extensionUser.profile.last_name : '');
  const fullName =
    firstAndLastName.trim().length !== 0
      ? firstAndLastName
      : extensionUser.profile?.username ?? 'User';

  const userTextRef = createRef<HTMLTextAreaElement>();
  const [editActive, setEditActive] = useState<boolean>(false);

  const message =
    props.model === AlchemyModel.ChatPDF && props.message.message?.startsWith('{"error')
      ? {
          ...props.message,
          message: '',
          alias: '',
          error: 'Failed to analyze: Could not read PDF',
        }
      : props.message;

  return (
    <div
      data-id={message.id}
      data-last={message._last ? true : undefined}
      className={
        'alchemy-message fade-in' +
        (message._generating ? ' generating' : '') +
        (message.model_message ? ' model-message' : ' user-message')
      }
    >
      {!message.model_message && (
        <SmoothVisibility
          as="button"
          visible={!editActive && !props.noEdit}
          onClick={() => setEditActive(true)}
          className="floating-edit-button"
        >
          <EditIcon />
        </SmoothVisibility>
      )}
      <Avatar
        url={!message.model_message && !message.error ? extensionUser.profile?.avatar : undefined}
        icon={
          !message.error && message.model_message ? (
            <BlobLogo />
          ) : message.error ? (
            <MessageErrorIcon />
          ) : undefined
        }
        size="tiny"
        className={message.error ? 'alchemy-generation-error' : ''}
        userName={fullName}
      />
      <div className="message-main">
        {!message.error ? (
          <>
            <div className="message-author">
              <span className="author">{message.model_message ? 'Blob' : fullName}</span>
            </div>
            <div
              className={
                'message-content' + (!message.model_message ? ' alchemy-user-message-content' : '')
              }
            >
              {message.platform && message.platform.type !== WebPlatform.None && (
                <>
                  <div className="message-platform">
                    <Avatar
                      size="tiny"
                      icon={
                        message.platform.type === WebPlatform.YouTube ? (
                          <YouTubeIcon color="var(--app-text)" />
                        ) : message.platform.type === WebPlatform.AISearch ? (
                          <SearchIconAlt />
                        ) : (
                          <WebIcon color="var(--app-text)" />
                        )
                      }
                      className="platform-logo"
                    />
                    <span>{message.platform.title}</span>
                  </div>
                  {message.platform.replyTo && (
                    <div className="reply-to">{message.platform.replyTo}</div>
                  )}
                </>
              )}
              {message.message && (
                <>
                  {editActive ? (
                    <ReactTextareaAutosize
                      name=""
                      id=""
                      className="editable-textarea"
                      ref={userTextRef}
                    >
                      {message.alias ?? message.message}
                    </ReactTextareaAutosize>
                  ) : (
                    <>
                      {message.model_message ? (
                        <SmoothText
                          markCitations={true}
                          videoURL={message._parentVideoURL}
                          customTheme={codeTheme}
                          text={message.alias ?? message.message ?? ''}
                          speed={1}
                          enabled={message._generating ?? false}
                        />
                      ) : (
                        <span className="user-message-text">
                          {message.alias ?? message.message}
                        </span>
                      )}
                    </>
                  )}
                </>
              )}

              {message._generating &&
              (!message.message || message.message?.length === 0) &&
              !message.url &&
              [AlchemyModel.DALLE3].includes(props.model ?? AlchemyModel.Claude35Sonnet) ? (
                <ProgressCircle autoMove={true} title={'Generating image'} progress={25} />
              ) : message._generating && !message.message && !message.url ? (
                <p className="text">
                  <span></span>
                </p>
              ) : null}

              <SmoothVisibility
                duration={200}
                visible={editActive ?? false}
                className="edit-actions"
              >
                <button
                  onClick={() => {
                    const text = userTextRef.current?.value;

                    setEditActive(false);

                    if (text && text.length > 0) {
                      props.onEdit?.(text);
                    } else {
                      toast.error('Text cannot be empty');
                    }
                  }}
                  className="submit"
                >
                  Save & Submit
                </button>
                <button className="cancel" onClick={() => setEditActive(false)}>
                  Cancel
                </button>
              </SmoothVisibility>
            </div>
            {message.url && message.url !== 'none' && !editActive && (
              <>
                {StringTools.getFileTypeInfo(message.url)?.mime.startsWith('image') ||
                !/\.\w+$/.test(message.url) ? (
                  <MessageImage
                    onClick={() => GraphicTools.viewImage(message.url)}
                    url={message.url}
                  />
                ) : (
                  <MessageAttachment filename={message.url.split('/').pop() ?? ''} />
                )}
              </>
            )}

            {(message._variants?.count ?? 0) > 1 && (
              <div className="message-version-selection">
                <div className="version">
                  <button
                    disabled={message._variants?.active === 1}
                    onClick={() => {
                      if ((message._variants?.active ?? 0) > 1) {
                        if (props.onChangeVersion) {
                          props.onChangeVersion((props?.message?._variants?.active ?? 0) - 1);
                        }
                      }
                    }}
                  >
                    <ArrowLeftIcon color="var(--app-light)" />
                  </button>
                  <span>
                    {message._variants?.active} / {message._variants?.count}
                  </span>
                  <button
                    disabled={message._variants?.active === message._variants?.count}
                    onClick={() => {
                      if ((message._variants?.active ?? 0) < (message._variants?.count ?? 0)) {
                        if (props.onChangeVersion) {
                          props.onChangeVersion((props?.message?._variants?.active ?? 0) + 1);
                        }
                      }
                    }}
                  >
                    <ArrowRightIcon color="var(--app-light)" />
                  </button>
                </div>
              </div>
            )}

            {message.model_message && !!message.message ? (
              <div className="message-footer">
                <>
                  {message.model_message && (
                    <>
                      <button
                        onClick={() => handleCopy(message.alias ?? message.message ?? '')}
                        className="message-action-button"
                      >
                        <span className="icon-wrapper">
                          <CopyIconChat copied={copied} />
                        </span>
                        {copied ? 'Copied' : 'Copy'}
                      </button>

                      <button onClick={() => props.onSave()} className="message-action-button">
                        <span className="icon-wrapper">
                          <DocumentPlusIcon size={16} />
                        </span>
                        {'Save'}
                      </button>
                      <button onClick={() => props.onRetry()} className="message-action-button">
                        <span className="icon-wrapper">
                          <RefreshIcon
                            style={{
                              width: '13px',
                              height: '13px',
                            }}
                            size={13}
                          />
                        </span>
                        {'Retry'}
                      </button>
                      <button onClick={() => props.onSnippet()} className="message-action-button">
                        <span className="icon-wrapper">
                          <BlobSidebarSnippetsIcon
                            style={{
                              width: '16px',
                              height: '16px',
                            }}
                            size={16}
                          />
                        </span>
                        {'Snippet'}
                      </button>
                      <SmoothVisibility
                        visible={props.showMoveToChatButton ?? false}
                        onClick={() => props.onMoveToChat?.()}
                        className="message-action-button"
                      >
                        <BlobSidebarChatsIcon style={{ width: '16px', height: '16px' }} size={16} />
                        {'Move to chat'}
                      </SmoothVisibility>
                    </>
                  )}
                </>
              </div>
            ) : (
              <div className="message-footer">
                <>
                  <button
                    onClick={() => handleCopy(message.alias ?? message.message ?? '')}
                    className="message-action-button"
                  >
                    <span className="icon-wrapper">
                      <CopyIconChat copied={copied} />
                    </span>
                    {copied ? 'Copied' : 'Copy'}
                  </button>
                </>
              </div>
            )}
          </>
        ) : (
          <div className="message-content">
            <SmoothText speed={2} text={message.error ?? ''} enabled={true}></SmoothText>
          </div>
        )}
      </div>
    </div>
  );
};
