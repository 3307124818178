import { useEffect, useState } from 'react';
import ReactTextareaAutosize from 'react-textarea-autosize';

import { ISnippet } from '../../../../../../../../service/interfaces';
import { HashIcon } from '../../../../../../../design/assets/svg/icons/HashIcon';
import { WarningIcon } from '../../../../../../../design/assets/svg/icons/WarningIcon';
import { SmoothVisibility } from '../../../../../../../design/components/smoothVisibility';

interface ICreateSnippet {
  onCreate: (snippet: ISnippet) => void;
  onCancel?: () => void;
  checkDoesHaveSameCode: (name: string) => boolean;
  isVisible: boolean;
  setIsVisible: (visible: boolean) => void;
  initialText?: string;
}

export const CreateSnippet = (props: ICreateSnippet) => {
  const [snippetCode, setSnippetCode] = useState<string>('');
  const [snippetText, setSnippetText] = useState<string>(
    props.initialText ?? window.getSelection()?.toString() ?? ''
  );

  const [haveSameName, setHaveSameName] = useState<boolean>(false);

  const [sent, setSent] = useState<boolean>(false);

  const handleSnippetCreation = () => {
    setSent(true);

    const haveSameName = props.checkDoesHaveSameCode(snippetCode);

    setHaveSameName(haveSameName);

    if (haveSameName) return;

    if (snippetCode.trim().length <= 0) return;
    if (snippetText.trim().length <= 0) return;

    const snippet = {
      code: snippetCode,
      text: snippetText,
    } as ISnippet;

    props.onCreate(snippet);

    props.setIsVisible(false);
  };

  useEffect(() => {
    setSnippetCode('');
    setSnippetText(props.initialText ?? window.getSelection()?.toString() ?? '');
    setSent(false);
    setHaveSameName(false);
  }, [props.isVisible]);

  return (
    <SmoothVisibility
      className={`snippet-editing ${props.isVisible ? 'opened' : ''}`}
      visible={props.isVisible}
    >
      <div className="title-wrapper">
        <HashIcon />
        <input
          onClick={e => {
            e.stopPropagation();
          }}
          onChange={e => {
            setSnippetCode(e.target.value);
          }}
          value={snippetCode}
          placeholder="Add your keyword"
        />
      </div>
      <SmoothVisibility
        className="validation-error"
        visible={sent && snippetCode.trim().length <= 0}
      >
        <WarningIcon />
        Enter a snippet name
      </SmoothVisibility>
      <SmoothVisibility className="validation-error" visible={sent && haveSameName}>
        <WarningIcon /> Keyword already in use
      </SmoothVisibility>
      <ReactTextareaAutosize
        placeholder="Add your snipet text"
        onClick={e => {
          e.stopPropagation();
        }}
        onChange={e => {
          setSnippetText(e.target.value);
        }}
        value={snippetText}
        maxRows={5}
        rows={5}
        minRows={5}
      />
      <div className="alchemy-footer">
        <SmoothVisibility
          className="validation-error"
          visible={sent && snippetText.trim().length <= 0}
        >
          <WarningIcon />
          Enter a snippet text
        </SmoothVisibility>
        <div className="action-buttons">
          <button
            onClick={() => {
              props.setIsVisible(false);
              props.onCancel?.();
            }}
          >
            Cancel
          </button>
          <button className="filled" onClick={handleSnippetCreation}>
            Create
          </button>
        </div>
      </div>
    </SmoothVisibility>
  );
};
