import cn from 'classnames';
import { Field, Form, Formik } from 'formik';
import { Fragment, useState } from 'react';
import Skeleton from 'react-loading-skeleton';

import { Button } from '../../../../components/Button';
import { Dropdown } from '../../../../components/Dropdown';
import { Modal } from '../../../../components/Modal';
import { selectModal, setModal } from '../../../../components/Modal/ModalSlice';
import { ModalTypes } from '../../../../components/Modal/types/enums/ModalTypes';
import { useExtensionServiceContext } from '../../../../external/extension/service/context';
import { Avatar } from '../../../../external/extension/ui/design/components/avatar';
import { useTypedDispatch } from '../../../../hooks/useTypedDispatch';
import { useTypedSelector } from '../../../../hooks/useTypedSelector';
import { IUser } from '../../../../models/ISettings';
import { selectTheme, ThemeOptions } from '../../../../redux/Theme';
import { getIsUserAuthorizedWithGoogle } from '../../../../services/storageService';
import { CropImageModal } from '../CropImageModal';
import styles from './AccountForm.module.scss';
import { AccountFormInput } from './AccountFormInput';
import { PairedInputs } from './PairedInputs';
import { usePairedInputsData } from './usePairedInputsData';
import { validationSchema } from './validationService';

interface IAccountForm {
  first_name: string;
  last_name: string;
  username: string;
  avatar: string;
  email: string;
  onSetSettings: (settings: IUser) => void;
  language_preference: string;
  isLoading: boolean;
}

export const AccountForm: React.FC<IAccountForm> = ({
  first_name = '',
  last_name = '',
  email = '',
  avatar = '',
  onSetSettings,
  language_preference = '',
  isLoading,
}) => {
  const dispatch = useTypedDispatch();
  const [profileImage, setProfileImage] = useState<null | string>(avatar);
  const { modalType } = useTypedSelector(selectModal);
  const theme = useTypedSelector(selectTheme);
  const [loadedImage, setLoadedImage] = useState<null | string>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const isGoogleAuthenticate = getIsUserAuthorizedWithGoogle();
  const pairedInputs = usePairedInputsData(isGoogleAuthenticate);

  const extensionService = useExtensionServiceContext();

  const handleDeleteBtnClick = () => {
    dispatch(setModal({ modalType: ModalTypes.DELETE_ACCOUNT }));
  };

  const handleImageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    const reader = new FileReader();

    reader.onload = () => {
      setLoadedImage(reader.result as string);
    };

    if (file) {
      reader.readAsDataURL(file);
      setIsModalOpen(true);
    }
  };

  const onCloseModal = () => {
    setIsModalOpen(false);
  };

  const languageArr = [{ value: 'en', label: 'English (United States)' }];

  const user = extensionService.useExtensionUser();
  const firstAndLastName = (first_name ?? 'User') + (last_name ? ' ' + last_name : '');
  const fullName =
    firstAndLastName.trim().length !== 0 ? firstAndLastName : user.profile.username ?? 'User';

  return (
    <>
      <Formik
        initialValues={{
          avatarFile: '',
          first_name: first_name,
          last_name: last_name,
          email: email,
          language_preference: language_preference,
          newEmail: '',
          old_password: '',
          new_password: '',
          confirmPassword: '',
        }}
        validationSchema={validationSchema}
        onSubmit={(values: any, { resetForm }) => {
          onSetSettings(values);
          resetForm({
            values: {
              ...values,
              avatarFile: '',
              newEmail: '',
              old_password: '',
              new_password: '',
              confirmPassword: '',
            },
          });
        }}
        enableReinitialize={true}
      >
        {({ setFieldValue }) => (
          <Form className={styles.accountForm} method="POST">
            <div className={styles.accountFormProfilePicture}>
              <Avatar size="big" url={profileImage} userName={fullName} />
              <div className={styles.accountFormProfileImageUpload}>
                <h3 className={cn(styles.accountFormProfileImageTitle)}>
                  {isLoading ? (
                    <div className={styles.accountFormProfileImageTitlePreview}>
                      <Skeleton />
                    </div>
                  ) : (
                    'Profile picture'
                  )}
                </h3>
                <div className={cn(styles.accountFormProfileImageDescription)}>
                  {isLoading ? (
                    <div className={styles.accountFormProfileImageDescriptionPreview}>
                      <Skeleton />
                    </div>
                  ) : (
                    'We support PNGs, JPEGs and GIFs under 10MB'
                  )}
                </div>
                <label
                  className={cn(styles.accountFormUploadLabel, {
                    [styles.purpleUploadLabel]: theme === ThemeOptions.Purple,
                  })}
                  htmlFor="avatarFile"
                >
                  {isLoading ? (
                    <div className={styles.wrapperUploadLoading}>
                      <Skeleton baseColor="#8961f3" highlightColor="#8961f3" />
                    </div>
                  ) : (
                    'Upload image'
                  )}
                  <Field
                    id="avatarFile"
                    className={styles.accountFormUploadInput}
                    type="file"
                    accept="image/png, image/jpeg, image/gif"
                    onChange={handleImageUpload}
                    name="avatarFile"
                    disabled={isLoading}
                  />
                </label>
              </div>
            </div>
            <div className={styles.accountFormInputsContainer}>
              {pairedInputs.map(({ title, firstInput, secondInput, id }) => {
                return firstInput?.type !== 'password' && secondInput?.type !== 'password' ? (
                  <PairedInputs
                    key={id}
                    title={title}
                    firstInput={firstInput}
                    secondInput={secondInput}
                  />
                ) : !isGoogleAuthenticate ? (
                  <Fragment key={id}>
                    <PairedInputs title={title} firstInput={firstInput} secondInput={secondInput} />

                    <AccountFormInput
                      label={'Old Password'}
                      name={'old_password'}
                      type={'password'}
                      placeholder={'Password'}
                      autoComplete="new-password"
                    />
                  </Fragment>
                ) : null;
              })}
            </div>
            <div className={styles.accountFormVoiceSettings}>
              <h2 className={styles.accountFormVoiceSettingsTitle}>Voice Settings</h2>
              <Dropdown
                onChange={val => {
                  setFieldValue('language_preference', val);
                }}
                options={languageArr}
                name="language_preference"
                ariaLabel="select a language"
                defaultValue={languageArr.find(obj => obj.value === language_preference)}
                customDropdownStyles={{
                  container: { border: 'none' },
                  control: { padding: '10px 16px' },
                }}
                isControlHovered
                isFullWidth
              />
            </div>
            <div className={styles.accountFormDangerZone}>
              <h2 className={styles.accountFormDangerTitle}>Danger Zone</h2>
              {isLoading ? (
                <>
                  <div className={styles.firstLoadingLine}>
                    <Skeleton />
                  </div>
                  <div className={styles.secondLoadingLine}>
                    <Skeleton />
                  </div>
                </>
              ) : (
                <>
                  <p className={styles.accountFormDangerDescription}>
                    If you want to permanently delete this account and all of its data, you can do
                    so below.
                  </p>
                  <button
                    type="button"
                    onClick={handleDeleteBtnClick}
                    className={styles.accountFormDangerDeleteBtn}
                    disabled={isLoading}
                  >
                    Delete account
                  </button>
                </>
              )}
            </div>
            <Button
              className={cn(styles.accountFormSaveBtn, {
                [styles.accountFormSaveBtnPurplePreview]:
                  isLoading && theme === ThemeOptions.Purple,
                [styles.accountFormSaveBtnGreenPreview]: isLoading && theme === ThemeOptions.Green,
              })}
              disabled={isLoading}
            >
              {isLoading ? (
                <div>
                  <Skeleton baseColor="#8961f3" />
                </div>
              ) : (
                'Save changes'
              )}
            </Button>
            <Modal
              isOpen={!!loadedImage && isModalOpen && !modalType}
              ariaLabel="crop image modal"
              onRequestClose={onCloseModal}
            >
              {loadedImage && (
                <CropImageModal
                  imgSrc={loadedImage}
                  onApply={file => {
                    setProfileImage(URL.createObjectURL(file));
                    setFieldValue('avatar', file);
                  }}
                  onClose={onCloseModal}
                />
              )}
            </Modal>
          </Form>
        )}
      </Formik>
    </>
  );
};
