import { useState } from 'react';

import { DownloadExtensionGradient } from '../../assets/svg/DownloadExtensionGradient';
import { useSetSettingsMutation } from '../../services/settings';
import { Button } from '../Button';
import styles from './DownloadExtensionReminder.module.scss';

export const DownloadExtensionReminder = () => {
  const [isLinkShown, setIsLinkShown] = useState(true);

  const [setSettings] = useSetSettingsMutation();

  const onDoNotShowAgain = async () => {
    try {
      await setSettings({ show_again: false }).unwrap();
      localStorage.setItem('show_download_extension', String(false));
      setIsLinkShown(false);
    } catch (error) {
      console.error('User Update Failed', error);
    }
  };

  if (!isLinkShown) return null;

  return (
    <div className={styles.downloadExtensionReminder}>
      <DownloadExtensionGradient />
      <div className={styles.downloadExtensionReminderTitleAndDescriptionWrapper}>
        <h1 className={styles.downloadExtensionReminderTitle}>Download Extension</h1>
        <p className={styles.downloadExtensionReminderDescription}>
          Download the extension to integrate Alchemy with your ChatGPT interface.
        </p>
      </div>
      <div className={styles.downloadExtensionReminderBtnsWrapper}>
        <Button
          onClick={onDoNotShowAgain}
          className={styles.downloadExtensionReminderBtn}
          isSecondary
        >
          Don’t show me again
        </Button>
        <Button
          onClick={() =>
            window.open(
              'https://chromewebstore.google.com/detail/alchemyai/kdadfibngmakkjckcaidihibnkkchjnh?hl=en&authuser=0',
              '_blank'
            )
          }
          className={styles.downloadExtensionBtn}
        >
          Download Extension
        </Button>
      </div>
    </div>
  );
};
