import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { NOTIFICATION_TYPES, useNotification } from '../../../hooks/useNotification';
import { errorMessages } from '../../../services/errors';
import {
  useGetAddSharedCustomPromptQuery,
  useGetSharedCustomPromptQuery,
} from '../../../services/promptLibrary';
import { PAGES_ENUM } from '../../../utils/constants';
import { handleError, handleSuccess } from '../../../utils/notifications';
import { ShareCustomPrompt } from '../ShareCustomPrompt';

interface IAddToLibraryError {
  status: string;
  message: string;
}

export const SharedPromptPage = () => {
  const [skip, setSkip] = useState(true);
  const { id } = useParams();
  const navigate = useNavigate();
  const { data, isFetching: isFetchingSharedCustomPromptQuery } = useGetSharedCustomPromptQuery(id);
  const authTokenFromStorage =
    localStorage.getItem('auth_token') || sessionStorage.getItem('auth_token');
  const { isSuccess, error } = useGetAddSharedCustomPromptQuery(id, {
    skip: skip,
  });

  useNotification({
    type: NOTIFICATION_TYPES.success,
    status: isSuccess,
    textSuccess: 'Successfully added to your library',
  });

  const onAddToLibrary = () => {
    if (!authTokenFromStorage) {
      navigate(PAGES_ENUM.SIGN_IN);

      return;
    }

    if (isSuccess) {
      handleSuccess('Already added to your library');
    }

    if (error && 'data' in error) {
      const errorData = error.data as IAddToLibraryError;

      if (errorData?.message === 'ADD_OWN_PROMPT_FORBIDDEN') {
        handleError(errorMessages.ADD_OWN_PROMPT_FORBIDDEN);
      }
    }

    setSkip(false);
  };

  useEffect(() => {
    if (error && 'data' in error) {
      const errorData = error.data as IAddToLibraryError;

      if (errorData?.message === 'ADD_OWN_PROMPT_FORBIDDEN') {
        handleError(errorMessages.ADD_OWN_PROMPT_FORBIDDEN);
      }
    }
  }, [error]);

  return (
    <ShareCustomPrompt
      data={data}
      alchemyButton={{
        buttonText: authTokenFromStorage ? 'Open Alchemy' : 'Login to Alchemy',
        onClick: () =>
          authTokenFromStorage
            ? navigate(`${PAGES_ENUM.HOME}?tab=customPrompts`)
            : navigate(PAGES_ENUM.SIGN_IN),
      }}
      promptManageButton={{
        buttonText: 'Add to library',
        onClick: () => onAddToLibrary(),
        plusIcon: true,
      }}
      customPromptId={id}
      isLoading={isFetchingSharedCustomPromptQuery}
    />
  );
};
