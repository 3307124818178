import axios from 'axios';
import { TranscriptResponse } from 'youtube-transcript';

import { YoutubeTranscript } from './lib/index.js';

export interface IYouTubeVideoBaseInfo {
  title: string;
  author_name: string;
  author_url: string;
  type: string;
  height: number;
  width: number;
  version: string;
  provider_name: string;
  provider_url: string;
  thumbnail_height: number;
  thumbnail_width: number;
  thumbnail_url: string;
  html: string;
}
export class YouTubeService {
  static async getTranscript(video: string): Promise<TranscriptResponse[] | null> {
    try {
      return await YoutubeTranscript.fetchTranscript(video, {
        lang: 'en',
      });
    } catch (_) {
      try {
        return await YoutubeTranscript.fetchTranscript(video, {});
      } catch (__) {
        return null;
      }
    }
  }

  static async getVideoBaseInfo(id: string): Promise<IYouTubeVideoBaseInfo | null> {
    const response = await axios.get(
      `https://www.youtube.com/oembed?format=json&url=https://www.youtube.com/watch?v=${id}`
    );

    if (response.data) {
      const json = response.data;

      if (typeof json === 'object') {
        return json as IYouTubeVideoBaseInfo;
      }
    }

    return null;
  }
}
