import { WebPlatform } from '../../platform';

export interface IConversation {
  id: number;
  name: string;
  model: AlchemyModel;
  chat_type: ConversationType;
  user?: number;
  messages?: IMessage[];
  created_at?: string;
  updated_at?: string;
  pdf_source_id?: string;
  current_message_id?: number;
}

export interface IMessagePlatform {
  type: WebPlatform;
  title?: string;
  author?: string;
  link?: string;
  thumbnail?: string;
  replyTo?: string;
}

export interface IMessageBase {
  chat?: number;
  previous_message?: number | null;
  attachments: number[];
  message?: string;
  alias?: string | null;
  platform?: IMessagePlatform | null;
  url?: string | null;
  model_message: boolean;
}
export interface IMessage extends IMessageBase {
  id: number;
  created_at: string;
  error?: string;
  _generating?: boolean;
  _variants?: {
    count: number;
    active: number;
  };
  _last?: boolean;
  _parentVideoURL?: string;
}

export interface IConversationMessages {
  data: IMessage[] | null;
  current_message_id?: number;
}

export interface ISearchResult {
  title: string;
  url: string;
  description: string;
}

export enum AlchemyModel {
  GPT35Turbo = 'gpt-3.5-turbo',
  GPT4Turbo = 'gpt-4-turbo',
  GPT4 = 'gpt-4',
  GPT4o = 'gpt-4o',
  DALLE3 = 'dall-e-3',
  Claude3Opus = 'claude-3-opus',
  Claude3Sonnet = 'claude-3-sonnet',
  Claude35Sonnet = 'claude-3.5-sonnet',
  Claude3Haiku = 'claude-3-haiku',
  ChatPDF = 'chat-pdf',
  // GeminiPro = 'gemini-pro',
  // Gemini15Pro = 'gemini-1.5-pro',
  OpenMistral = 'open-mistral-7b',
}

export interface IGenerationEvents {
  onIdGenerated?: (id: string) => void;
  onStreamOpen?: (event: ISSEEvent) => void;
  onCreatedChat?: (chat: IConversation, event: ISSEEvent) => void;
  onTitleGenerated?: (title: string, event: ISSEEvent) => void;
  onCreatedUserMessage?: (message: IMessage, event: ISSEEvent) => void;
  onCreatedAssistantMessage?: (message: IMessage, event: ISSEEvent) => void;
  onGenerationStarted?: (event: ISSEEvent) => void;
  onGenerationChunkReceived?: (chunk: IGenerationChunk, event: ISSEEvent) => void;
  onImageGenerated?: (url: string, event: ISSEEvent) => void;
  onGenerationEnded?: (event: ISSEEvent) => void;
  onUpdatedAssistantMessage?: (message: IMessage, event: ISSEEvent) => void;
  onGenerationError?: (message: string, event: ISSEEvent) => void;
  onCompleted?: () => void;
  onAborted?: () => void;
}

export type GenerationEventCallback = (event: ISSEEvent) => void;
export type GenerationEventCallbackWithParams<P> = (param: P, event: ISSEEvent) => void;

export interface IGenerationSubscriber {
  id: string;
  events: IGenerationEvents;
}

export interface IGenerationProps extends IGenerationEvents {
  request: IGenerationRequest;
  signal?: AbortSignal;
}

export interface IGenerationRequest {
  model: AlchemyModel;
  image_generation: boolean;
  image_process: boolean;
  create_chat: boolean;
  chat?: {
    chat_type?: ConversationType;
    visible_chat?: boolean;
    chat_title?: string;
    create_chat_model?: AlchemyModel;
  };
  save_message: boolean;
  message: IMessageBase;
  session_id?: string;
  regenerate?: boolean;
  context?: number[];
}

export enum ConversationType {
  Chat = 0,
  Synth = 1,
}

export interface IGenerationChunk {
  message_id: number | null;
  diff: string;
}

export interface ISSEEvent {
  type: SSEEventType;
  sent_at: number | null;
  session_id: string | null;
  data: any;
}

export enum SSEEventType {
  StreamOpen = 'stream-open',
  KeepAlive = 'keep-alive',
  CreatedChat = 'created-chat',
  TitleGenerated = 'title-generated',
  CreatedUserMessage = 'created-user-message',
  CreatedAssistantMessage = 'created-assistant-message',
  GenerationStarted = 'generation-started',
  GenerationChunkReceived = 'generation-chunk-received',
  GenerationError = 'generation-error',
  ImageGenerated = 'image-generation',
  GenerationEnded = 'generation-ended',
  UpdatedAssistantMessage = 'updated-assistant-message',
  Done = 'done',
}

export interface ISSESubscriberBase {
  request: IGenerationRequest;
  onEvent: (data: ISSEEvent, subscriber?: ISSESubscriber) => void;
}

export interface ISSESubscriber extends ISSESubscriberBase {
  id: string;
}

export interface IGenerationQueueItem {
  id: string;
  props: IGenerationProps;
  resolve: () => void;
  reject: (reason?: any) => void;
}
