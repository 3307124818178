import { useEffect, useMemo } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import { ExtensionService } from '../external/extension/service';
import { ExtensionServiceContext } from '../external/extension/service/context';
import { ExtensionEvents, useExtensionEventListener } from '../external/extension/service/events';
import { ExtensionEventType } from '../external/extension/service/events/types';
import { PAGES_ENUM } from '../utils/constants';

export const RequireAuth = () => {
  const authToken = localStorage.getItem('auth_token') || sessionStorage.getItem('auth_token');

  useEffect(() => {
    if (authToken) {
      ExtensionEvents.dispatch(ExtensionEventType.CastAuthorization, {
        token: authToken,
      });
    }
  }, [authToken]);

  useExtensionEventListener(
    ExtensionEventType.BlobExtensionLoaded,
    () => {
      const authToken = localStorage.getItem('auth_token') || sessionStorage.getItem('auth_token');

      if (authToken)
        ExtensionEvents.dispatch(ExtensionEventType.CastAuthorization, {
          token: authToken,
        });
    },
    []
  );

  const extensionService = useMemo(
    () => new ExtensionService({ token: authToken, tier: null }),
    [authToken]
  );

  if (!authToken) {
    return <Navigate to={PAGES_ENUM.SIGN_IN} />;
  }

  return (
    <ExtensionServiceContext.Provider value={extensionService}>
      <Outlet />
    </ExtensionServiceContext.Provider>
  );
};
