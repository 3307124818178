import moment from 'moment';
import { useEffect, useState } from 'react';

import { Button } from '../../../../components/Button';
import { Dropdown } from '../../../../components/Dropdown';
import { Modal } from '../../../../components/Modal';
import { setModal } from '../../../../components/Modal/ModalSlice';
import { ModalTypes } from '../../../../components/Modal/types/enums/ModalTypes';
import { NOTIFICATION_TYPES, useNotification } from '../../../../hooks/useNotification';
import { useStripeRedirectToStripePage } from '../../../../hooks/useStripeRedirectToStripePage';
import { useTypedDispatch } from '../../../../hooks/useTypedDispatch';
import { IUser } from '../../../../models/ISettings';
import {
  ISubscriptionLevel,
  SUBSCRIPTION_TIER,
  SUBSCRIPTION_TYPE,
} from '../../../../models/ISubscription';
import { useGetSettingsQuery, useLazyGetSettingsQuery } from '../../../../services/settings';
import {
  useGetSubscriptionLevelQuery,
  useUnsubscribeUserMutation,
} from '../../../../services/subscribtion';
import { pricingPlan } from '../../../../utils/mocks/Settings';
import { ErrorNode } from '../../../../utils/notifications';
import styles from './BillingAndPlansTabContent.module.scss';
import { BillingCard } from './BillingCard';
import { IBillingCard } from './BillingCard/IBillingCard';

const timeType = [
  { label: 'Monthly', value: SUBSCRIPTION_TYPE.MONTH },
  { label: 'Yearly', value: SUBSCRIPTION_TYPE.YEAR },
];

export const BillingAndPlansTabContent = () => {
  const { data: userData } = useGetSettingsQuery();
  const { subscription_end_date, is_canceled_subscription } = userData || {};
  const { data: subscriptionData, isLoading: isLoadingSubscriptionLevel } =
    useGetSubscriptionLevelQuery();
  const [currTimeType, setCurrTimeType] = useState<{
    label: string;
    value: SUBSCRIPTION_TYPE;
  }>({ label: 'Monthly', value: SUBSCRIPTION_TYPE.MONTH });
  const dispatch = useTypedDispatch();
  const subscriptionLevel = subscriptionData?.subscription_level;
  const subscriptionType = subscriptionData?.subscription_type;
  const isFreeSubscriptionLevel = subscriptionLevel === 0;
  const isTrialActivated = subscriptionData?.trial_activated;
  const subscriptionEndDate = !!subscription_end_date;
  const isValidDate = moment(subscription_end_date).isValid();

  useEffect(() => {
    if (subscriptionType !== null || subscriptionType !== undefined) {
      const selectedType = timeType.find(period => Number(period.value) === subscriptionType);

      if (selectedType) {
        setCurrTimeType(selectedType);
      }
    }
  }, [subscriptionType]);

  const formattedDateTime =
    isValidDate && moment(subscription_end_date).format('DD-MM-YYYY [at] HH:mm');

  const getIsActiveBtn = (tier: SUBSCRIPTION_TIER) => {
    return (
      (subscriptionLevel === tier && subscriptionType === Number(currTimeType.value)) ||
      (subscriptionLevel === tier && subscriptionType === null)
    );
  };

  const { onStripeRedirectToStripePage } = useStripeRedirectToStripePage();

  const handleRedirect = (card: IBillingCard) => {
    if (getIsActiveBtn(card.tier)) return;
    if (subscriptionLevel > card.tier) {
      dispatch(
        setModal({
          modalType: ModalTypes.ACCOUNT_DOWNGRADE_WARNING_MODAL,
          modalProps: { tier: card.tier, type: currTimeType.value },
        })
      );

      return;
    }
    onStripeRedirectToStripePage({ tier: card.tier, type: currTimeType.value });
  };

  const [isShowModalCancelSubscription, setIsShowModalCancelSubscription] = useState(false);

  const [unsubscribeUser, resUnsubscribeUser] = useUnsubscribeUserMutation();
  const [getProfile] = useLazyGetSettingsQuery();

  const onShowModalCancelSubscription = () => setIsShowModalCancelSubscription(true);
  const onCloseModalCancelSubscription = () => setIsShowModalCancelSubscription(false);
  const onCancelSubscriptionRequest = async () => {
    const res = (await unsubscribeUser()) as { data: ISubscriptionLevel & { status: number } };

    if (res.data?.status >= 200 && res.data?.status < 400) {
      (await getProfile()) as { data: IUser & { status: number } };
    }
  };

  useNotification({
    type: NOTIFICATION_TYPES.success,
    status: resUnsubscribeUser.isSuccess,
    textSuccess: 'Successfully unsubscribed',
    afterCreate: onCloseModalCancelSubscription,
  });

  useNotification({
    type: NOTIFICATION_TYPES.error,
    status: resUnsubscribeUser.isError,
    error: resUnsubscribeUser.error as ErrorNode,
  });

  return (
    <>
      <Modal
        isOpen={isShowModalCancelSubscription}
        ariaLabel=""
        onRequestClose={() => setIsShowModalCancelSubscription(false)}
      >
        <CancelSubscriptionModal
          onCancelSubscriptionRequest={onCancelSubscriptionRequest}
          onCloseModalCancelSubscription={onCloseModalCancelSubscription}
        />
      </Modal>
      {isShowModalCancelSubscription && <>1</>}
      <div className={styles.billingAndPlans}>
        <div className={styles.plansTitleAndDescription}>
          <h2 className={styles.plansTitle}>Choose a plan</h2>
          <p className={styles.plansDescription}>
            View your plan information or switch plans according to your needs
          </p>
        </div>
        <div className={styles.billingContent}>
          <div className={styles.billingPeriod}>
            <div className={styles.billingPeriodTitle}>
              Pricing Table
            </div>
            <div className={styles.billingPeriodSelector}>
              <Dropdown
                key={subscriptionType}
                options={timeType}
                ariaLabel="select a period"
                defaultValue={
                  timeType.find(period => Number(period.value) === subscriptionType) || timeType[0]
                }
                onChange={(type: SUBSCRIPTION_TYPE) =>
                  setCurrTimeType(timeType.find(t => t.value === type))
                }
                customDropdownStyles={{ menu: { zIndex: '2' } }}
              />
            </div>
          </div>
          <div className={styles.billingContentCardsContainer}>
            {pricingPlan?.[currTimeType.value].map(card => (
              <BillingCard
                onClick={() => handleRedirect(card)}
                key={card.id}
                {...card}
                getIsActiveBtn={getIsActiveBtn}
                isLoading={isLoadingSubscriptionLevel}
                isTrialActivated={isTrialActivated}
              />
            ))}
          </div>
        </div>
        {!isFreeSubscriptionLevel && !isLoadingSubscriptionLevel && (
          <div className={styles.subscription}>
            <h3>Unsubscribe</h3>
            <p className={styles.textSubscription}>
              {!is_canceled_subscription
                ? 'If you want to unsubscribe from Alchemy, you can do so below'
                : `Your subscription will expire on ${subscriptionEndDate && formattedDateTime}`}
            </p>

            {!is_canceled_subscription && (
              <div onClick={onShowModalCancelSubscription} className={styles.cancelBtn}>
                Cancel Subscription
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

const CancelSubscriptionModal = ({
  onCloseModalCancelSubscription,
  onCancelSubscriptionRequest,
}: any) => {
  const [isConfirmCancelSubscription, setIsConfirmCancelSubscription] = useState(false);

  const textBeforeConfirm =
    'You are about to unsubscribe your Alchemy plan. Are you sure you want to continue?';
  const textAfterConfirm =
    'Your Alchemy Plus plan account will not renew and will be canceled, but is still available until the end of your billing period';

  const modalButtonsBeforeConfirm = (
    <>
      <Button type="button" onClick={onCloseModalCancelSubscription} isSecondary>
        No, go back
      </Button>
      <Button type="button" onClick={() => setIsConfirmCancelSubscription(true)} isRed>
        Yes, cancel
      </Button>
    </>
  );

  const modalButtonsAfterConfirm = (
    <>
      <Button type="button" onClick={onCancelSubscriptionRequest} isSecondary>
        Understood
      </Button>
    </>
  );

  return (
    <>
      <h2 className={styles.modalTitle}>Cancel Subscription</h2>

      <div className={styles.content}>
        {isConfirmCancelSubscription ? textAfterConfirm : textBeforeConfirm}
      </div>
      <div className={styles.modalButtons}>
        {isConfirmCancelSubscription ? modalButtonsAfterConfirm : modalButtonsBeforeConfirm}
      </div>
    </>
  );
};
