import { Icon } from '../../components/Icon/Icon';
import { IIcon } from '../../components/Icon/IIcon';

export const CheckConfirmationIcon = (props: IIcon) => {
  return (
    <Icon width="20" height="20" {...props}>
      <path
        d="M13.5418 6.45801C13.5418 6.45801 9.5835 9.58301 8.54183 13.5413L6.4585 10.6247"
        stroke="#30FF21"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
};
