/* eslint-disable react/no-unescaped-entities */
import './index.scss';

import cn from 'classnames';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { isMacOs } from 'react-device-detect';
import ReactMarkdown from 'react-markdown';
import ReactTextareaAutosize from 'react-textarea-autosize';
import gfm from 'remark-gfm';

import { Badge } from '../../../../../../../components/Badge';
import { ICategory, IPrompt } from '../../../../../interfaces';
import { BaseExtensionService } from '../../../../../service/base';
import EyeIconAlt from '../../../../design/assets/svg/icons/EyeIconAlt';
import { HelpIcon } from '../../../../design/assets/svg/icons/HelpIcon';
import LinkIcon from '../../../../design/assets/svg/icons/LinkIcon';
import { SlashIcon } from '../../../../design/assets/svg/icons/SlashIcon';
import { StarIcon } from '../../../../design/assets/svg/icons/StarIcon';
import { Button } from '../../../../design/components/button';
import { Modal } from '../../../../design/components/modal';
import { Tooltip } from '../../../../design/components/tooltip';
import { VoiceInput } from '../../../../design/components/voice';
import { PromptDetailsModalHotkeys } from './hotkeys';

export const PromptDetailsModal: React.FC<{
  onClose: any;
  onEdit?: (prompt: IPrompt) => void;
  onPromptUpdate: (newState: IPrompt, update: 'all' | 'favorite' | 'command') => void;
  onClickSendPrompt: (inputs: { [key: string]: string }) => void;
  isCustomCategory: boolean;
  visible?: boolean;
  prompt?: IPrompt | null;
}> = ({ onClose, isCustomCategory, onClickSendPrompt, onPromptUpdate, visible, prompt }: any) => {
  const [isFavorite, setIsFavorite] = useState<boolean>(prompt?.is_favourite ?? false);
  const [isAddCommand, setIsAddCommand] = useState<boolean>(prompt?.is_in_command ?? false);

  const [inputValues, setInputValues] = useState<{ [key: string]: string }>({});

  useEffect(() => {
    let timeout: any = null;

    if (!visible) {
      timeout = setTimeout(() => {
        setInputValues({});
      }, 500);
    }
    setIsFavorite(prompt?.is_favourite ?? false);
    setIsAddCommand(prompt?.is_in_command ?? false);

    setFocusedInputId(0);

    return () => {
      !!timeout && clearTimeout(timeout);
    };
  }, [visible]);

  useEffect(() => {
    setIsFavorite(prompt?.is_favourite ?? false);
    setIsAddCommand(prompt?.is_in_command ?? false);
  }, [prompt]);

  const onFavorite = () => {
    if (!prompt) return;

    setIsFavorite(!isFavorite);
    onPromptUpdate(
      {
        ...prompt,
        is_favourite: !isFavorite,
      },
      'favorite'
    );
  };

  const onAddToCommand = () => {
    if (!prompt) return;

    setIsAddCommand(!isAddCommand);
    onPromptUpdate(
      {
        ...prompt,
        is_in_command: !isAddCommand,
      },
      'command'
    );
  };

  const formRef = useRef<HTMLFormElement | null>(null);
  const [errorInputs, setErrorInputs] = useState<string[]>([]);
  const [focusedInputId, setFocusedInputId] = useState<number>(0);

  const [isSendButtonDisabled, setIsSendButtonDisabled] = useState<boolean>(false);

  const onSend = () => {
    try {
      setIsSendButtonDisabled(true);
      if (!formRef.current) return;
      formRef.current.classList.add('submitted');
      const inputs = prompt?.inputs.map((input: any) => input.variable_name) ?? [];
      const errors = inputs.filter((key: any) => !inputValues[key] || inputValues[key] === '');

      setErrorInputs(errors);

      if (errors.length === 0) {
        const lightModeEnabled = false;

        if (!lightModeEnabled) {
          onClickSendPrompt(inputValues);
        } else {
          const filledTemplate = prompt
            ? BaseExtensionService.fillPromptTemplate(prompt, inputValues)
            : '';

          document.dispatchEvent(
            new CustomEvent('alchemy-lightmode-speechrecogn-text', {
              detail: filledTemplate,
            })
          );
          setTimeout(() => {
            const button = [
              ...(
                document.querySelector('#prompt-textarea')?.parentElement?.parentElement as any
              ).querySelectorAll('button'),
            ].pop() as HTMLButtonElement;

            if (button) button.click();
          }, 10);
        }

        onClose();
      }
    } catch (err) {
      setIsSendButtonDisabled(false);
    }
  };

  const handleInputChange = (value: string, variableName: string) => {
    setInputValues({
      ...inputValues,
      [variableName]: value,
    });
  };

  useEffect(() => {
    if (visible) setIsSendButtonDisabled(false);
  }, [visible]);

  const isEmptyCategories = prompt?.categories.length === 0;

  return (
    <Modal
      className="prompt-details-modal"
      title={
        <div className="prompt-details-title-wrapper">
          Prompt details
          <div className="prompt-details-icon-wrapper">
            <Tooltip position="top" text={`For fields that don't apply, just type 'N/A'.`}>
              <HelpIcon />
            </Tooltip>
          </div>
        </div>
      }
      visible={visible}
      hasCloseButton={true}
      onClose={() => {
        onClose();
      }}
    >
      <PromptDetailsModalHotkeys onSubmit={onSend} />
      <div className="prompt-details-modal-content">
        <div className="content-left-part">
          <div className="answer">
            <div className="flex">
              <div className="categories-wrapper">
                {((prompt?.categories ?? []) as ICategory[])?.map(category => {
                  return (
                    <Badge
                      key={category?.id}
                      icon={category?.icon}
                      title={category?.parent?.name ?? category?.name}
                      color={category?.color ?? '#0BA5EC'}
                      background={category?.background_color ?? 'rgba(11, 165, 236, 0.16)'}
                      subcategory={category?.parent && category?.name}
                    />
                  );
                })}
              </div>
              <ul className="selected">
                <button
                  onClick={onFavorite}
                  className={cn('promptSaveBtn', {
                    promptSaveBtnActive: isFavorite,
                  })}
                >
                  <StarIcon />
                </button>
                <button
                  className={cn('promptCommandBtn', {
                    promptCommandBtnActive: isAddCommand,
                  })}
                  onClick={onAddToCommand}
                >
                  <SlashIcon />
                </button>
              </ul>
            </div>
            <h3 className={isEmptyCategories ? 'shortTitle' : ''}>
              {prompt?.name}
              {prompt?.chain_prompt && <LinkIcon />}
            </h3>
            <ReactMarkdown remarkPlugins={[gfm]} className="answer-description">
              {prompt?.description ?? ''}
            </ReactMarkdown>

            <ul className="stats">
              <li>
                <EyeIconAlt />
                <p>{prompt?.amount_of_lookups}</p>
              </li>
            </ul>
          </div>
        </div>
        <div className="content-right-part">
          <form ref={formRef}>
            {prompt?.inputs.map((inp: any, index: any) => {
              return (
                <div className="input" key={inp.variable_name}>
                  <div className={`input-textarea-wrapper`}>
                    <label>{inp.variable_name} </label>
                    <TextArea
                      key={inp.variable_name}
                      inp={inp}
                      handleInputChange={handleInputChange}
                      inputValues={inputValues}
                      isLastInput={index === prompt.inputs.length - 1}
                      isFocused={focusedInputId === index}
                      setFocused={() => {
                        setFocusedInputId(index);
                      }}
                      onSend={onSend}
                      onFocusNext={() => {
                        if (index === prompt.inputs.length - 1) onSend();
                        if (index < prompt.inputs.length - 1) setFocusedInputId(prev => prev + 1);
                      }}
                    />
                  </div>
                  <div
                    className={`prompt_detail_modal_textarea_error_message ${
                      errorInputs.some(input => input === inp.variable_name) ? 'is-visible' : ''
                    }`}
                  >
                    For fields that don't apply, just type 'N/A'.
                  </div>
                </div>
              );
            })}
          </form>
        </div>
      </div>
      <div className="prompt-action">
        <Button
          size="mini"
          className="send-prompt"
          onClick={onSend}
          disabled={isSendButtonDisabled}
        >
          Send prompt
        </Button>
      </div>
    </Modal>
  );
};

interface Props {
  inp: any;
  inputValues: any;
  handleInputChange: (value: string, variableName: string) => void;
  isLastInput: boolean;
  isFocused: boolean;
  setFocused: () => void;
  onSend: () => void;
  tabIndex?: number;
  onFocusNext: () => void;
}

const TextArea: React.FC<Props> = (props: any) => {
  const ref2 = useRef<HTMLTextAreaElement>(null);
  const [prompt, setPrompt] = useState<string>('');
  const [isRecording, setIsRecording] = useState<boolean>(false);

  useEffect(() => {
    props.handleInputChange(prompt, props.inp.variable_name);
  }, [prompt]);

  useEffect(() => {
    isRecording && ref2.current?.focus();
  }, [isRecording]);

  useEffect(() => {
    if (props.isFocused) ref2.current?.focus();
  }, [props.isFocused]);

  const handleTextAreaChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    props.handleInputChange(e.target.value, props.inp.variable_name);
  };

  return (
    <>
      <VoiceInput
        onChange={isActive => {
          setIsRecording(isActive);
        }}
        prompt={props.inputValues[props.inp.variable_name] || ''}
        setPrompt={setPrompt}
        tabIndex={-1}
      />
      <ReactTextareaAutosize
        placeholder={
          props.inp.placeholder.length > 0 ? props.inp.placeholder : props.inp.variable_name
        }
        value={props.inputValues[props.inp.variable_name] || ''}
        onChange={handleTextAreaChange}
        ref={ref2}
        className={`prompt_detail_modal_textarea`}
        title=""
        required
        style={{ overflow: 'hidden' }}
        onKeyDown={e => {
          if (e.key === 'Enter') {
            if (isMacOs ? e.metaKey : e.ctrlKey) {
              e.preventDefault();
              props.onSend();

              return;
            }
            e.preventDefault();
            props.onFocusNext();
          }
        }}
        onFocus={props.setFocused}
      />
    </>
  );
};

export default TextArea;
