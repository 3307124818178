export interface IBlobSidebarSynthIcon {
  style?: React.CSSProperties;
  size?: number;
}

export default function BlobSidebarSynthIcon({
  size = 24,
  ...props
}: IBlobSidebarSynthIcon) {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      style={props.style}
    >
      <path
        d='M15.0019 6.48512C15.0019 9.24655 13.5134 11.7351 10.7519 11.7351C13.5134 11.7351 15.0019 14.2237 15.0019 16.9851C15.0019 14.2237 16.4905 11.7351 19.2519 11.7351C16.4905 11.7351 15.0019 9.24655 15.0019 6.48512Z'
        fill='currentColor'
      />
      <path
        d='M8.00195 14.4851C8.00195 16.142 6.40881 17.7351 4.75195 17.7351C6.40881 17.7351 8.00195 19.3283 8.00195 20.9851C8.00195 19.3283 9.59509 17.7351 11.2519 17.7351C9.59509 17.7351 8.00195 16.142 8.00195 14.4851Z'
        fill='currentColor'
      />
      <path
        d='M7.50207 5.41455C7.50207 7.0714 6.81347 7.59169 5.15662 7.59169C6.81347 7.59169 7.50207 8.1531 7.50207 9.80995C7.50207 8.1531 8.03038 7.59169 9.68723 7.59169C8.03038 7.59169 7.50207 7.0714 7.50207 5.41455Z'
        fill='currentColor'
      />
      <path
        d='M15.0019 6.48512C15.0019 9.24655 13.5134 11.7351 10.7519 11.7351C13.5134 11.7351 15.0019 14.2237 15.0019 16.9851C15.0019 14.2237 16.4905 11.7351 19.2519 11.7351C16.4905 11.7351 15.0019 9.24655 15.0019 6.48512Z'
        stroke='currentColor'
        strokeWidth='1.8'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M8.00195 14.4851C8.00195 16.142 6.40881 17.7351 4.75195 17.7351C6.40881 17.7351 8.00195 19.3283 8.00195 20.9851C8.00195 19.3283 9.59509 17.7351 11.2519 17.7351C9.59509 17.7351 8.00195 16.142 8.00195 14.4851Z'
        stroke='currentColor'
        strokeWidth='1.8'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M7.50207 5.41455C7.50207 7.0714 6.81347 7.59169 5.15662 7.59169C6.81347 7.59169 7.50207 8.1531 7.50207 9.80995C7.50207 8.1531 8.03038 7.59169 9.68723 7.59169C8.03038 7.59169 7.50207 7.0714 7.50207 5.41455Z'
        stroke='currentColor'
        strokeWidth='1.8'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
