import { FC } from 'react';
import { Link } from 'react-router-dom';

import { RocketIcon } from '../../../assets/svg/RocketIcon';
import BlobSidebarChatsIcon from '../../../external/extension/ui/design/assets/svg/icons/BlobSidebarChatsIcon';
import BlobSidebarClipsIcon from '../../../external/extension/ui/design/assets/svg/icons/BlobSidebarClipsIcon';
import BlobSidebarNotesIcon from '../../../external/extension/ui/design/assets/svg/icons/BlobSidebarNotesIcon';
import BlobSidebarPromptLibraryIcon from '../../../external/extension/ui/design/assets/svg/icons/BlobSidebarPromptLibraryIcon';
import BlobSidebarSnippetsIcon from '../../../external/extension/ui/design/assets/svg/icons/BlobSidebarSnippetsIcon';
import BlobSidebarSynthIcon from '../../../external/extension/ui/design/assets/svg/icons/BlobSidebarSynthIcon';
import { FillerIcon } from '../../../external/extension/ui/design/assets/svg/icons/FillerIcon';
import SearchIcon from '../../../external/extension/ui/design/assets/svg/icons/SearchIcon';
import { PAGES_ENUM } from '../../../utils/constants';
import { NavList } from './NavList';
import styles from './SidebarNavigation.module.scss';

type Props = {
  isLoading: boolean;
  isSubscribtionFetching: boolean;
  isUpgradeNeeded: boolean;
};

export const SidebarNavigation: FC<Props> = ({
  isLoading,
  isUpgradeNeeded,
  isSubscribtionFetching,
}) => {
  const mainNav = [
    {
      title: 'Chats',
      icon: <BlobSidebarChatsIcon />,
      link: '/chat',
    },
    {
      title: 'Synthesize',
      icon: <BlobSidebarSynthIcon />,
      link: '/synthesize',
    },
    {
      title: 'AI Search',
      icon: <SearchIcon />,
      link: '/search',
    },
    {
      title: 'Prompts',
      icon: <BlobSidebarPromptLibraryIcon />,
      link: PAGES_ENUM.PROMPTS,
      subItems: [
        {
          icon: <FillerIcon />,
          title: 'Alchemy',
          link: PAGES_ENUM.PROMPTS + '?tab=all',
        },
        {
          icon: <FillerIcon />,
          title: 'Favorite',
          link: PAGES_ENUM.PROMPTS + '?tab=favorite',
        },
        {
          icon: <FillerIcon />,
          title: 'Custom',
          link: PAGES_ENUM.PROMPTS + '?tab=custom',
        },
      ],
    },
    {
      title: 'Notes',
      icon: <BlobSidebarNotesIcon />,
      link: PAGES_ENUM.ALL_CONTENT,
    },
    {
      title: 'Snippets',
      icon: <BlobSidebarSnippetsIcon />,
      link: PAGES_ENUM.SNIPPETS,
    },
    {
      title: 'Clipboard',
      icon: <BlobSidebarClipsIcon />,
      link: PAGES_ENUM.CLIPBOARD,
    },
  ];

  return (
    <div className={styles.nav}>
      <div className={styles.navContent}>
        <NavList items={mainNav} isLoading={isLoading} className={styles.mainNav} />
        <div className={styles.navCommunityAndUpgrade}>
          {isUpgradeNeeded && !isSubscribtionFetching && (
            <Link
              className={styles.navUpgradeBtn}
              to={`${PAGES_ENUM.SETTINGS}?tab=billing-and-plans`}
            >
              <RocketIcon /> Upgrade to Pro
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};
