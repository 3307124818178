import React from 'react';

import { CloseIcon } from '../../../assets/svg/CloseIcon';
import { NotificationErrorIcon } from '../../../assets/svg/NotificationErrorIcon';
import styles from './ErrorToast.module.scss';

interface IProps {
  closeToast?: () => void;
  text: string;
}

export const ErrorToast: React.FC<IProps> = ({ closeToast, text }) => {
  return (
    <div className={styles.errorToast}>
      <div className={styles.errorToastIconAndTextWrapper}>
        <NotificationErrorIcon />
        {text}
      </div>
      <div
        className={styles.errorToastCloseBtn}
        onClick={closeToast}
        aria-label="close"
        tabIndex={0}
      >
        <CloseIcon />
      </div>
    </div>
  );
};
