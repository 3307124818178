/* eslint-disable @typescript-eslint/no-empty-function */
import './index.scss';

import { extract } from '@extractus/article-extractor';
import { useEffect, useMemo, useState } from 'react';

import { useExtensionServiceContext } from '../../../service/context';
import { IClip, IClipBase, IClipFolder } from '../../../service/interfaces';
import { useAsyncProcessManagerContext } from '../../../tools/async/context';
import { GraphicTools } from '../../../tools/graphics';
import { RequestTools } from '../../../tools/request';
import { StringTools } from '../../../tools/string';
import ArrowRightIcon from '../../design/assets/svg/icons/ArrowRightIcon';
import { Button } from '../../design/components/button';
import { LoadingOverlay } from '../../design/components/loading';
import { confirm } from '../../design/components/modal/confirmation';
import { prompt } from '../../design/components/modal/prompt';
import { SmoothVisibility } from '../../design/components/smoothVisibility';
import { Clip } from './components/clip';
import { ClipsModal } from './components/clip/modal';
import { ClipFolder } from './components/folder';
import { AddToFolderModal } from './components/folder/modal';
import { ClipSearch } from './components/search';

export interface IClipsScreen {
  visible?: boolean;
}
export function ClipsScreen({ visible = true }: IClipsScreen) {
  const extensionService = useExtensionServiceContext();

  const asyncProcessManager = useAsyncProcessManagerContext();

  const isAuthorized = extensionService.useExtensionAuthorization();

  const [searchQuery, setSearchQuery] = useState<string>('');

  const [folders, setFolders] = useState<IClipFolder[]>([]);
  const [clips, setClips] = useState<IClip[]>([]);

  const [selectedFolder, setSelectedFolder] = useState<IClipFolder | null>(null);
  const [lastSelectedFolder, setLastSelectedFolder] = useState<IClipFolder | null>(null);

  const [isCreateClipModalVisible, setIsCreateClipModalVisible] = useState<boolean>(false);
  const [isEditClipModalVisible, setIsEditClipModalVisible] = useState<boolean>(false);
  const [isAddClipToFolderModalVisible, setIsAddClipToFolderModalVisible] =
    useState<boolean>(false);

  const [modalClip, setModalClip] = useState<IClip | null>(null);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [loadingError] = useState<string | null>(null);

  const filteredFolders = useMemo(() => {
    if (selectedFolder) return [];

    if (searchQuery.length === 0) {
      return folders;
    }

    const queryLower = searchQuery.toLocaleLowerCase();

    return folders
      .filter(folder => folder.title.toLocaleLowerCase().includes(queryLower))
      .sort((a, b) => {
        const aTitle = a.title.toLocaleLowerCase();
        const bTitle = b.title.toLocaleLowerCase();

        const aStartsWith = aTitle.startsWith(queryLower);
        const bStartsWith = bTitle.startsWith(queryLower);

        if (aStartsWith && !bStartsWith) {
          return -1;
        }
        if (!aStartsWith && bStartsWith) {
          return 1;
        }

        const aIncludes = aTitle.includes(queryLower);
        const bIncludes = bTitle.includes(queryLower);

        if (aIncludes && !bIncludes) {
          return -1;
        }
        if (!aIncludes && bIncludes) {
          return 1;
        }

        return 0;
      });
  }, [folders, searchQuery, selectedFolder]);

  const filteredClips = useMemo(() => {
    if (searchQuery.length === 0) {
      return clips.filter(clip =>
        !selectedFolder ? !clip.folder : clip.folder === selectedFolder.id
      );
    }

    const queryLower = searchQuery.toLocaleLowerCase();

    return clips
      .filter(
        clip =>
          (clip.title.toLocaleLowerCase().includes(queryLower) ||
            clip.url.toLocaleLowerCase().includes(queryLower)) &&
          (!selectedFolder ? !clip.folder : clip.folder === selectedFolder.id)
      )
      .sort((a, b) => {
        const aTitle = a.title.toLocaleLowerCase();
        const bTitle = b.title.toLocaleLowerCase();

        const aStartsWith = aTitle.startsWith(queryLower);
        const bStartsWith = bTitle.startsWith(queryLower);

        if (aStartsWith && !bStartsWith) {
          return -1;
        }
        if (!aStartsWith && bStartsWith) {
          return 1;
        }

        const aIncludes = aTitle.includes(queryLower);
        const bIncludes = bTitle.includes(queryLower);

        if (aIncludes && !bIncludes) {
          return -1;
        }
        if (!aIncludes && bIncludes) {
          return 1;
        }

        return 0;
      });
  }, [clips, searchQuery, selectedFolder]);

  const prepareClip = async (url: string, title?: string): Promise<IClipBase> => {
    const clip: IClipBase = {
      url,
      title: 'New clip',
      folder: null,
    };

    try {
      const article = await extract(url);

      if (article) {
        clip.title = article.title ?? clip.title;
        clip.preview = article.image;
      } else {
        const html = await RequestTools.fetchHtmlContent(url);

        if (html) {
          const urlDocument = RequestTools.htmlStringToDocument(html);

          clip.title =
            urlDocument.title.length === 0
              ? GraphicTools.getPageTitle(urlDocument) ?? clip.title
              : urlDocument.title;
          clip.preview = GraphicTools.getPagePreviewImageURL(urlDocument) ?? undefined;
        }
      }
    } catch (e) {
      // pass
    }

    if (title && title.length > 0) {
      clip.title = title;
    }

    if (!clip.preview) {
      clip.preview = 'none';
    }

    return clip;
  };

  async function refreshClips() {
    await asyncProcessManager?.doProcess({
      name: 'Fetch clips and folders',
      action: async () => {
        const data = await extensionService.getClipsAndFolders();

        setFolders(data?.folders ?? []);
        setClips(data?.clips ?? []);
      },
    });
  }

  const handleCreate = async () => {
    setModalClip({
      title: document.title,
      url: window.location.href,
      folder: selectedFolder?.id,
    } as any);

    setIsCreateClipModalVisible(true);
    const clip = await prepareClip(window.location.href);

    setModalClip(clip as IClip);
  };

  const handleCreateFolder = async (callback?: (folder: IClipFolder | null) => void) => {
    prompt({
      title: 'Please enter folder name',
      inputPlaceholder: 'Folder name',
      confirmButtonText: 'Create',
      theme: 'blob',
      primary: true,
      handleConfirm: async name => {
        await asyncProcessManager?.doProcess({
          name: 'Create folder',
          action: async () => {
            const folder = await extensionService.createClipFolder({
              title: name,
              color: 'default',
            });

            callback?.(folder);

            refreshClips();
          },
        });
      },
      handleCancel: async () => {
        callback?.(null);
      },
    });
  };

  const handleEdit = async (clip: IClip) => {
    setModalClip(clip);

    setIsEditClipModalVisible(true);
  };

  const handleAddClipToFolder = async (clip: IClip, folder: IClipFolder | null) => {
    await asyncProcessManager?.doProcess({
      name: 'Add clip to folder',
      action: async () => {
        await extensionService.updateClip(clip.id, {
          ...clip,
          folder: folder?.id ?? null,
        });
      },
    });
  };

  useEffect(() => {}, [searchQuery]);

  useEffect(() => {
    if (isAuthorized) refreshClips();
  }, [isAuthorized]);

  return (
    <>
      <SmoothVisibility className="clips-screen smooth-section" visible={visible}>
        <header>
          <div className="header-left-side-wrapper">
            <span
              className={selectedFolder ? 'clickable' : ''}
              onClick={() => setSelectedFolder(null)}
            >
              Clipboard
            </span>
            <SmoothVisibility visible={!!selectedFolder} className="active-folder">
              <ArrowRightIcon color="currentColor" />
              {StringTools.truncate(lastSelectedFolder?.title ?? '', 20)}
            </SmoothVisibility>
            <SmoothVisibility as="button" visible={true} className="new-btn" onClick={handleCreate}>
              + Add new
            </SmoothVisibility>
          </div>
        </header>
        <div className="clips-wrapper">
          <div className="sticky-header">
            <ClipSearch query={searchQuery} setQuery={setSearchQuery} />
          </div>

          <div className="clips-list">
            {filteredFolders.map((folder, index) => (
              <ClipFolder
                key={index}
                folder={folder}
                clips={clips.filter(clip => clip.folder === folder.id)}
                onClick={() => {
                  setLastSelectedFolder(folder);
                  setSelectedFolder(folder);
                }}
                onDelete={() =>
                  confirm({
                    text: 'Are you really sure you want to delete this folder?',
                    danger: true,
                    primary: true,
                    theme: 'blob',
                    handleConfirm: async () => {
                      await asyncProcessManager?.doProcess({
                        name: 'Delete folder',
                        action: async () => {
                          setIsLoading(true);
                          await extensionService.deleteClipFolder(folder.id);
                          await refreshClips();
                          setIsLoading(false);
                        },
                      });
                    },
                  })
                }
                onEdit={() => {
                  prompt({
                    title: 'Please enter new name for your folder',
                    danger: true,
                    primary: true,
                    theme: 'blob',
                    defaultValue: folder.title,
                    inputPlaceholder: 'New folder',
                    handleConfirm: async name => {
                      await asyncProcessManager?.doProcess({
                        name: 'Rename folder',
                        action: async () => {
                          setIsLoading(true);
                          await extensionService.updateClipFolder(folder.id, {
                            ...folder,
                            title: name,
                          });
                          await refreshClips();
                          setIsLoading(false);
                        },
                      });
                    },
                  });
                }}
                onDropClip={async clip => {
                  await handleAddClipToFolder(clip, folder);
                  refreshClips();
                }}
                searchQuery={searchQuery}
              />
            ))}
            {filteredClips.map(clip => (
              <Clip
                onAddToFolder={() => {
                  if (folders.length > 0) {
                    setModalClip(clip);
                    setIsAddClipToFolderModalVisible(true);
                  } else {
                    handleCreateFolder(async folder => {
                      if (folder) {
                        await handleAddClipToFolder(clip, folder);
                        refreshClips();
                      }
                    });
                  }
                }}
                onClick={() => {
                  window.location.href = clip.url;
                }}
                onDelete={() => {
                  confirm({
                    text: 'Are you really sure you want to delete this clip?',
                    danger: true,
                    primary: true,
                    theme: 'blob',
                    handleConfirm: async () => {
                      await asyncProcessManager?.doProcess({
                        name: 'Delete clip',
                        action: async () => {
                          setIsLoading(true);
                          await extensionService.deleteClip(clip.id);
                          await refreshClips();
                          setIsLoading(false);
                        },
                      });
                    },
                  });
                }}
                onEdit={() => {
                  handleEdit(clip);
                }}
                searchQuery={searchQuery}
                key={clip.id}
                clip={clip}
              />
            ))}

            <SmoothVisibility
              className="empty-message"
              visible={filteredClips.length === 0 && filteredFolders.length === 0 && !isLoading}
            >
              {loadingError ? (
                <div className="loading-error">
                  <span>Failed to load clips</span>
                  <Button onClick={() => refreshClips()}>Try again</Button>
                </div>
              ) : (
                <span>
                  {searchQuery.length > 0
                    ? 'Clips for your request not found...'
                    : 'Your clips will be shown here...'}
                </span>
              )}
            </SmoothVisibility>

            <LoadingOverlay active={isLoading} />
          </div>
        </div>
      </SmoothVisibility>

      <AddToFolderModal
        visible={isAddClipToFolderModalVisible}
        folders={folders}
        clips={clips}
        onRefresh={() => {
          refreshClips();
        }}
        onCreateFolder={async () => {
          setIsAddClipToFolderModalVisible(false);
          handleCreateFolder(() => {
            setIsAddClipToFolderModalVisible(true);
          });
        }}
        onClose={() => {
          setIsAddClipToFolderModalVisible(false);
          setModalClip(null);
        }}
        onAddToFolder={async folder => {
          if (modalClip) {
            await handleAddClipToFolder(modalClip, folder);
            refreshClips();
            setIsAddClipToFolderModalVisible(false);
            setModalClip(null);
          }
        }}
        onMoveToGeneral={async () => {
          if (modalClip) {
            await handleAddClipToFolder(modalClip, null);
            refreshClips();
            setIsAddClipToFolderModalVisible(false);
            setModalClip(null);
          }
        }}
      />

      <ClipsModal
        visible={isCreateClipModalVisible}
        mode="create"
        clip={modalClip}
        onSubmit={async (url: string, title: string) => {
          setIsCreateClipModalVisible(false);

          const clip = await prepareClip(url, title);

          await extensionService.createClip({
            ...clip,
            folder: selectedFolder?.id ?? null,
          });
          await refreshClips();
        }}
        onCancel={() => {
          setIsCreateClipModalVisible(false);
        }}
      />

      <ClipsModal
        visible={isEditClipModalVisible}
        mode="edit"
        clip={modalClip}
        onSubmit={async (url: string, title: string) => {
          setIsEditClipModalVisible(false);
          if (!modalClip) return;

          const clip =
            url !== modalClip.url ? await prepareClip(url, title) : { ...modalClip, title };

          await extensionService.updateClip(modalClip?.id, clip);
          await refreshClips();
        }}
        onCancel={() => {
          setIsEditClipModalVisible(false);
        }}
      />
    </>
  );
}
